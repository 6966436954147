import React from 'react';
import styles from './BlogSidebar.module.css';
import BlogSidebarCard from '../BlogSidebarCard/BlogSidebarCard.jsx';

import { Paper, Typography } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';

import api from '../../../axios';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary
}));

const BlogSidebar = () => {
  const [blogList, setBlogList] = React.useState([]);

  React.useEffect(() => {
    api
      .get('/content/blogs')
      .then((res) => {
        console.log(res);
        setBlogList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className={styles.container}>
      <Item>
        <Typography variant="h6" sx={{ mb: 5 }}>
          MORE BLOGS FROM COGNIX
        </Typography>

        {blogList.map((item, idx) => (
          <BlogSidebarCard {...item} key={idx} />
        ))}
      </Item>
    </div>
  );
};

export default BlogSidebar;
