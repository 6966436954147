import React from 'react';
import styles from './BlogView.module.css';
import BlogSidebar from '../BlogSidebar/BlogSidebar';
import BlogTemplate from '../BlogTemplate/BlogTemplate';
// import { Header } from '../../landingNew/main';

import api from '../../../axios';
import { useParams, useNavigate } from 'react-router-dom';

const logo = process.env.PUBLIC_URL + '/assets/mock/logo.png';

const BlogView = () => {
  const params = useParams();
  let navigate = useNavigate();

  const [data, setData] = React.useState({});

  React.useEffect(() => {
    api
      .get(`/content/blogs/${params.id}`)
      .then((res) => {
        console.log(res.data);
        setData({
          title: res.data.title,
          background_color: res.data.background_color,
          content: res.data.content,
          category: res.data.category.name,
          date_to_live: res.data.date_to_live.substring(0, 10),
          image: res.data.image,
          fromApi: true
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.id]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <div>
        <header id="header" className="fixed-top" style={{ background: 'black' }}>
          <div className="container d-flex align-items-center">
            <h1 className="logo me-auto">
              <a className="logo" onClick={() => navigate('/')}>
                <img src={logo} alt="" className="img-fluid" style={{ marginRight: '10px' }} />
              </a>
              <a href="#" style={{ color: 'white' }} onClick={() => navigate('/')}>
                CogniX
              </a>
            </h1>
            <nav id="navbar" className="navbar">
              <ul>
                <li>
                  <a
                    className="nav-link scrollto active"
                    href="#landing_hero"
                    onClick={() => navigate('/')}>
                    Home
                  </a>
                </li>
                <li>
                  <a
                    className="nav-link scrollto"
                    href="#landing_cognixintro"
                    onClick={() => navigate('/')}>
                    About
                  </a>
                </li>
                <li>
                  <a
                    className="nav-link scrollto"
                    href="#landing_yogigintro"
                    onClick={() => navigate('/')}>
                    Yogi-G
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto" href="#team" onClick={() => navigate('/')}>
                    Team
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto" href="#footer" onClick={() => navigate('/')}>
                    Contact
                  </a>
                </li>
                {/* <li>
                  <a
                    className='nav-link scrollto'
                    onClick={() => navigate(`/blog/${params.id}`)}
                  >
                    Blogs
                  </a>
                </li> */}
                <li>
                  <a className="nav-link scrollto" onClick={() => navigate('/instructor')}>
                    Instructors
                  </a>
                </li>
                <li>
                  <a className="getstarted scrollto" onClick={() => navigate('/signup')}>
                    Get Started
                  </a>
                </li>
              </ul>
              <i className="bi bi-list mobile-nav-toggle"></i>
            </nav>
          </div>
        </header>
      </div>
      <div className={styles.container}>
        <div className={styles.left}>
          <BlogTemplate {...data} />
        </div>
        <div className={styles.right}>
          <BlogSidebar />
        </div>
      </div>
    </div>
  );
};

export default BlogView;
