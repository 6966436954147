import React from 'react';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { experimentalStyled as styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Grid,
  Paper,
  TextField,
  Button,
  Card,
  CardMedia,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SwatchesPicker } from 'react-color';

import BlogTemplate from '../BlogTemplate/BlogTemplate';

// api import
import api from '../../../axios';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BlogEditor = () => {
  // states
  const [img, setImg] = React.useState(null);
  const [title, setTitle] = React.useState('');
  const [date, setDate] = React.useState(dayjs());
  const [content, setContent] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [color, setColor] = React.useState('#FFF');
  const [data, setData] = React.useState({});
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarData, setSnackbarData] = React.useState({});
  const [blogs, setBlogs] = React.useState([]);
  const [toUpdate, setToUpdate] = React.useState(false);
  const [updateId, setUpdateId] = React.useState(0);

  React.useEffect(() => {
    api
      .get('/content/blogs')
      .then((res) => {
        setBlogs(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    const dateString = date.toISOString();
    const year = dateString.substring(0, 4);
    const month = dateString.substring(5, 7);
    const day = dateString.substring(8, 10);

    const postDate = day + '/' + month + '/' + year + ' 00:00';

    setData({
      title: title,
      image: img,
      content: content,
      date_to_live: postDate,
      category: category,
      background_color: color,
      fromApi: false
    });
  }, [img, title, date, content, category, color]);

  const handleDateChange = (newValue) => {
    setDate(newValue);
    console.log(date.toISOString());
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const publishBlog = () => {
    if (!title.length || !content.length || !category.length || !img || !color.length) {
      setSnackbarData({
        color: 'error',
        message: 'Enter All Fields'
      });
      setSnackbarOpen(true);
      return;
    }
    console.log(data);
    api
      .post('/content/blogs/', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        console.log(res);
        setSnackbarData({
          color: 'success',
          message: 'Blog Saved Successfully'
        });
        setSnackbarOpen(true);
      })
      .catch((err) => {
        setSnackbarData({
          color: 'error',
          message: 'Some error occured'
        });
        setSnackbarOpen(true);
        console.log(err);
      });

    // setData({});
  };

  const handleUpdate = (id) => {
    setUpdateId(id);
    api
      .get(`/content/blogs/${id}`)
      .then((res) => {
        console.log(res.data);
        setTitle(res.data.title);
        setContent(res.data.content);
        setCategory(res.data.category.name);

        setToUpdate(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editBlog = (id) => {
    if (!title.length || !content.length || !category.length || !color.length) {
      setSnackbarData({
        color: 'error',
        message: 'Enter All Fields'
      });
      setSnackbarOpen(true);
      return;
    }
    if (img === null) {
      delete data.image;
    } else {
      data.image = img;
    }
    console.log(data);
    api
      .patch(`/content/blogs/${id}/`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        console.log(res.data);
        setSnackbarData({
          color: 'success',
          message: 'Blog Updated Successfully'
        });
        setSnackbarOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const PopUp = (props) => {
    return (
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={props.color} sx={{ width: '100%' }}>
          {props.message}
        </Alert>
      </Snackbar>
    );
  };

  return (
    <Box>
      <Item sx={{ width: '95%', margin: '0px auto 10px auto' }}>
        <Typography variant="h2" align="center" sx={{ p: 2, fontWeight: 'medium' }}>
          BLOG EDITOR
        </Typography>
      </Item>

      <Box>
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12} sm={6}>
            <Item>
              <Grid container sx={{ p: 1 }}>
                <Grid item xs={12} sx={{ mb: 9 }}>
                  <Item>
                    <Typography variant="h5" align="left" sx={{ mb: 5, fontWeight: 'bold' }}>
                      BLOG TITLE
                    </Typography>

                    <TextField
                      label="Enter Blog Title"
                      fullWidth
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Item>
                </Grid>

                <Grid item xs={12} sx={{ mb: 5 }}>
                  <Item>
                    <Typography variant="h5" align="left" sx={{ mb: 5, fontWeight: 'bold' }}>
                      BLOG IMAGE
                    </Typography>

                    <Button
                      variant="outlined"
                      component="label"
                      endIcon={<PhotoCameraIcon />}
                      sx={{ mb: 2 }}
                      color="error">
                      Upload Image
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={(event) => {
                          setImg(event.target.files[0]);
                        }}
                      />
                    </Button>

                    {img ? (
                      <Card>
                        <CardMedia
                          component="img"
                          image={URL.createObjectURL(img)}
                          height="100%"
                          width="100%"
                        />
                      </Card>
                    ) : (
                      <></>
                    )}
                  </Item>
                </Grid>

                <Grid item xs={12} sx={{ mb: 5 }}>
                  <Item>
                    <Typography variant="h5" sx={{ mb: 5, fontWeight: 'bold' }}>
                      BLOG CATEGORY
                    </Typography>
                    <TextField
                      label="Enter Category"
                      fullWidth
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    />
                  </Item>
                </Grid>

                <Grid item xs={12} sx={{ mb: 5 }}>
                  <Item>
                    <Typography variant="h5" align="left" sx={{ mb: 5, fontWeight: 'bold' }}>
                      BLOG LIVE DATE
                    </Typography>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Blog Date"
                        inputFormat="DD/MM/YYYY"
                        value={date}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Item>

                  <Item sx={{ mt: 2 }}>
                    {!toUpdate ? (
                      <Button
                        variant="contained"
                        color="error"
                        endIcon={<ShortcutIcon />}
                        onClick={publishBlog}>
                        PUBLISH BLOG
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="warning"
                        endIcon={<ShortcutIcon />}
                        onClick={() => editBlog(updateId)}>
                        EDIT THE BLOG
                      </Button>
                    )}
                  </Item>
                </Grid>
              </Grid>
            </Item>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Item>
              <Grid container sx={{ p: 1 }}>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Item>
                    <Typography align="left" sx={{ mb: 4, fontWeight: 'bold' }} variant="h5">
                      BLOG CONTENT
                    </Typography>
                    <TextField
                      label="Enter the blog content"
                      fullWidth
                      multiline
                      minRows={26}
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                    />
                  </Item>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Item>
                    <Typography align="left" sx={{ mb: 4, fontWeight: 'bold' }} variant="h5">
                      CHOOSE BLOG BACKGROUND COLOR
                    </Typography>
                    <SwatchesPicker
                      onChange={(e) => {
                        setColor(e.hex);
                      }}
                    />
                  </Item>
                </Grid>

                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Item>
                    <Typography align="left" sx={{ mb: 4, fontWeight: 'bold' }} variant="h5">
                      EDIT PREVIOUS BLOGS
                    </Typography>
                    {blogs.map((item, idx) => (
                      <Typography sx={{ mb: 2 }} key={idx}>
                        <Button variant="outlined" onClick={() => handleUpdate(item.id)}>
                          {item.title}
                        </Button>
                      </Typography>
                    ))}
                  </Item>
                </Grid>
              </Grid>
            </Item>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Item>
          <BlogTemplate {...data} />
        </Item>
      </Box>
      <Box>
        <PopUp {...snackbarData} />
      </Box>
    </Box>
  );
};

export default BlogEditor;
