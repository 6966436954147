import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css';
//core
import 'primereact/resources/primereact.min.css';
//icons
import 'primeicons/primeicons.css';

//snackbar
import { SnackbarProvider } from 'notistack';

// cookies
import { CookiesProvider } from 'react-cookie';

// import redux
import { Provider } from 'react-redux';
import initialStore from './initialStore';

// router import
import { RouterProvider } from 'react-router-dom';
import router from './routes';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={initialStore}>
      <CookiesProvider>
        <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
          <RouterProvider router={router} />
        </SnackbarProvider>
      </CookiesProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
