import React from 'react';
// import Chart from '../../Components/chart/Chart';
import styles from '../../css/Instructor/Chat.module.css';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Stack, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from '../../axios';
import { useSnackbar } from 'notistack';

import { useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import Sidebar from '../../Components/Sidebar/Sidebar';
import navList, { navBottomList } from './Navlist';
// import zIndex from '@mui/material/styles/zIndex';
const Chat = () => {
    const { enqueueSnackbar } = useSnackbar();
    const dummymsgs = [
        {

            sender: "user1",
            message: "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
            reciever: "instructor1",
            timestamp: "1694766211291"
        },
        {
            sender: "instructor1",
            message: "hello",
            reciever: "user1",
            timestamp: "1694767513598"
        },
        {
            sender: "user1",
            message: "hi",
            reciever: "instructor1",
            timestamp: "1694767523598"
        },
    ]

    const [prescription, setPrescription] = React.useState('');
    const [messages, setMessages] = React.useState(dummymsgs);
    const [refresh, setRefresh] = React.useState(0);
    const containerChatRef = React.useRef(null);
    const scrollToBottom = () => {
        if (containerChatRef.current) {
            containerChatRef.current.scrollTop = containerChatRef.current.scrollHeight;

        }
      };
      const scrollToTop = () => {
        if (containerChatRef.current) {
            containerChatRef.current.scrollTop = 0;

        }
      };

    const getTimestamp = (item) => {
        return new Date(item.timestamp).getTime(); // Convert timestamp to milliseconds
    };
    const [msg, setMsg] = React.useState('');

    React.useEffect(() => {
        //console.log(new Date().getTime().toLocaleString());
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem(`access-token`)}` }
        };
        //console.log(localStorage.getItem(`access-token`));
        axios
            .get('users/chat/?user=test@test.com', config)
            .then((res) => {
                console.log(res.data);
                const sortedData = [...res.data].sort((a, b) => getTimestamp(a) - getTimestamp(b));
                console.log(sortedData);
                setMessages(sortedData);
                scrollToBottom();

            })
            .catch((error) => {
                console.log(error);
                if (error.request.status == 401) {
                  enqueueSnackbar('Session Expired, Login Again', { variant: 'error' });
                  localStorage.removeItem('access-token');
                  navigate(`/instructor/login`);
                }
            });


            console.log("refreshed");
    }, [refresh]);
    const handleChange = (event) => {
        setPrescription(event.target.value);
    };
      const navigate = useNavigate();
    //   const navigateToChats = () => {
    //     navigate('/instructor/chats');
    //   };


    function handleSave() {

        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem(`access-token`)}` }
        };

        axios.post('users/chat/', { user: "test@test.com", message: msg }, config).then(() => {
            //console.log(res.data);
            setRefresh(refresh + 1);

        })
            .catch((error) => {
                console.log(error);
                if (error.request.status == 401) {
                  enqueueSnackbar('Session Expired, Login Again', { variant: 'error' });
                  localStorage.removeItem('access-token');
                  navigate(`/instructor/login`);
                }
            });
        // const item={
        //     sender: "instructor1",
        //     reciever: "user1",
        //     message: msg,
        //     timestamp: new Date().getTime()

        // }
        // setMessages([...messages,item]);
        //console.log(messages);
        setMsg('');

        console.log("refresh set true")


    }
    return (
        <div className={styles.single}>
            <div className={styles.top}>
                <div className={styles.left}>
                    <Sidebar navList={navList} navBottomList={navBottomList} />

                    <div className={styles.item}>
                        <img
                            src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                            alt=""
                            className={styles.itemImg}
                        />
                        <div className={styles.details}>
                            <h1 className="itemTitle">Jenny Doe</h1>
                            <div className={styles.detailItem}>
                                <span className={styles.itemKey}>Email:</span>
                                <span className={styles.itemValue}>jennydoe@gmail.com</span>
                            </div>
                            <div className={styles.detailItem}>
                                <span className={styles.itemKey}>Age:</span>
                                <span className={styles.itemValue}>23</span>
                            </div>
                            <div className={styles.detailItem}>
                                <span className={styles.itemKey}>Weight:</span>
                                <span className={styles.itemValue}>58kg</span>
                            </div>
                            <div className={styles.detailItem}>
                                <span className={styles.itemKey}>Gender:</span>
                                <span className={styles.itemValue}>Female</span>
                            </div>
                            <div className={styles.detailItem}>
                                <Box sx={{ minWidth: 100, marginTop: 0 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Prescription</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={prescription}
                                            label="Prescription"
                                            onChange={handleChange}>
                                            <MenuItem value={1}>BackPain</MenuItem>
                                            <MenuItem value={2}>NeckPain</MenuItem>
                                            <MenuItem value={3}>Diabetes</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.item}>

                        <div className={styles.details}>
                            <h1 className="itemTitle">Statistics</h1>
                            <h4 className={styles.stattitle}>Videos Completed - 24</h4>
                            <h4 className={styles.stattitle}>Prescription Completion - 75%</h4>

                            <div className={styles.detailItem}>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.containerchat} ref={containerChatRef}>
                    {/* {messages.map(item => (
                            item.sender === "instructor1" ? (<div className={styles.itemchatsnd} key={item.timestamp} style={{textAlign:'right'}}><div className={styles.snd1}>{item.message}</div></div>) : (<div className={styles.itemchatrcv}  key={item.timestamp}><div className={styles.rcv1}>{item.sender}</div><div className={styles.rcv2}>{item.message}</div></div>)
            
            ))} */}
 {messages.map((item, index) => (
  <div key={item.timestamp}>
    {(index === 0 || new Date(item.timestamp).getDate()!== new Date(messages[index - 1].timestamp).getDate()) && (
      <div className=''><div className={styles.datechat}>{new Date(item.timestamp).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: '2-digit' })}</div></div>
    )}
      {/* {console.log(new Date(item.timestamp))} */}

    {item.is_instructor === true ? (
      <div className={styles.itemchatsnd} style={{ textAlign: 'right' }}>
        <div className={styles.snd1}>{item.text}        
        <span className={styles.time}>
          {(new Date(item.timestamp)).getHours()}:{(new Date(item.timestamp)).getMinutes()}
        </span></div>

      </div>
    ) : (
      <div className={styles.itemchatrcv}>
        <div className={styles.rcv1}>{item.user}</div>
        <div className={styles.rcv2}>{item.text}        
        <span className={styles.time}>
          {(new Date(item.timestamp)).getHours()}:{(new Date(item.timestamp)).getMinutes()}
        </span></div>

      </div>
    )}
  </div>
))}
                    {/* <div className={styles.details}>
                            hello
                        </div> */}


                </div>
                <div className={styles.msgTxt}>
                    <TextField
                        required
                        id="outlined-required"
                        value={msg}
                        onChange={(e) => setMsg(e.target.value)}
                        label="Enter Message"
                        sx={{ width: '80%' }}
                        size='small'

                    />
                    <div></div>
                    <Button variant="contained" sx={{ width: '10vw' }} onClick={() => handleSave()}>
                        Send
                    </Button>
                    <Button variant="outlined" sx={{ width: '1vw' }} style={{fontSize:'0.8rem'}} onClick={() => scrollToTop()}>
                       Top   
                    </Button>
                </div>
            </div>


            <div className={styles.bottom}>
                <Stack direction="row" spacing={80} style={{marginLeft:'20%',marginRight:'20%'}}>
                    <Button variant="contained" color="success">
                        Accept
                    </Button>
                    <Button variant="outlined" color="error">
                        Reject
                    </Button>

                </Stack>
            </div>
            {/* <div className={styles.chat}>
                <div className={styles.chat_top}>
                    <div className={styles.chat_left}>fwsfsf</div>
                </div>
            </div> */}
        </div>
    );
};

export default Chat;
