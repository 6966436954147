import React from 'react';
import { Typography } from '@mui/material';
import TopBar from '../../Components/TopBar/TopBar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import axios from '../../axios';
//import { Stack } from 'immutable';
//import { color } from '@mui/system';
var cardStyle = {
  display: 'block',
  width: '340px',
  transitionDuration: '0.3s',
  height: '363px',
  marginLeft: '10px',
  backgroundColor: 'white'
};

var userRequestsCard = {
  display: 'block',
  flex:'1',
  height:'363px',
  marginLeft:'10px',
  transitionDuration: '0.3s',
  backgroundColor: 'white'
};

var innerCardStyle = {
  flex:'1',
  display: 'block',
  width: '130px',
  transitionDuration: '0.3s',
  height: '116px',
  margin: '10px',
  backgroundColor: '#FFF7F5'
};

const columns = [
  { field: 'id', headerName: 'ID', width: 100 },
  { field: 'Name', headerName: 'Name', width: 200 },
  { field: 'Date', headerName: 'Date', type: 'date', width: 200, valueGetter: (params) => new Date(params.value) },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 200,
    renderCell: () => {
      return (
        <div className="classAction" style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Link to="/instructor/single" style={{ textDecoration: 'none' }}>
            <div
              className="viewButton"
              style={{
                padding: '2px 5px',
                color: 'white',
                backgroundColor: '#DA6B3F',
                cursor: 'pointer',
                border: '1px solid rgba(0, 0, 0, 0.2)',
                borderRadius: '5px'
              }}>
              View
            </div>
          </Link>
          <div
            className="deleteButton"
            style={{
              padding: '2px 5px',
              color: 'white',
              backgroundColor: '#DA6B3F',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.2)',
              borderRadius: '5px'
            }}>
            Delete
          </div>
        </div>
      );
    }
  }
];

const user_columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'Name', headerName: 'Name', width: 130 },
  // { field: 'userame', headerName: 'User Name', width: 130 },

  {
    field: 'created_at',
    headerName: 'Date',
    type: 'date',
    width: 120
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 200,
    renderCell: () => {
      return (
        <div className="classAction" style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Link to="/instructor/single" style={{ textDecoration: 'none' }}>
            <div
              className="viewButton"
              style={{
                padding: '2px 5px',
                color: 'darkblue',
                cursor: 'pointer',
                border: '1px dotted rgba(0, 0, 139, 0.596)',
                borderRadius: '5px'
              }}>
              View
            </div>
          </Link>
        </div>
      );
    }
  }
];

const chat_columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'Name', headerName: 'Name', width: 130 },

  {
    field: 'date_of_req',
    headerName: 'Date',
    type: 'date',
    width: 120
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 200,
    renderCell: () => {
      return (
        <div className="classAction" style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Link to="/instructor/chats" style={{ textDecoration: 'none' }}>
            <div
              className="viewButton"
              style={{
                padding: '2px 5px',
                color: 'darkblue',
                cursor: 'pointer',
                border: '1px dotted rgba(0, 0, 139, 0.596)',
                borderRadius: '5px'
              }}>
              Chat With User
            </div>
          </Link>
        </div>
      );
    }
  }
];


const rows = [
  { id: 1, Name: 'Jon', Date: '01-01-2021' },
  { id: 2, Name: 'Cersei', Date: '01-01-2021' },
  { id: 3, Name: 'Jaime', Date: '01-01-2021' },
  { id: 4, Name: 'Arya', Date: '01-01-2021' },
  { id: 5, Name: 'Daenerys', Date: '01-01-2021' },
  { id: 6, Name: 'Henry', Date: '01-01-2021' },
  { id: 7, Name: 'Ferrara', Date: '01-01-2021' },
  { id: 8, Name: 'Rossini', Date: '01-01-2021' },
  { id: 9, Name: 'Harvey', Date: '01-01-2021' },
  { id: 10, Name: 'Harvey', Date: '01-01-2021' },
  { id: 11, Name: 'Harvey', Date: '01-01-2021' },
  { id: 12, Name: 'Harvey', Date: '01-01-2021' },
  { id: 13, Name: 'Harvey', Date: '01-01-2021' }
];

const Dashboard = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(`access-token`)}` }
  };
  const [details, setDetails] = React.useState({});
  const [statistics, setStatistics] = React.useState({});
  const [userdata, setUserdata] = React.useState({});

  React.useEffect(() => {
    axios
      .get(`/users/profile/`, config)
      .then((response) => {
        console.log(response);
        setDetails(response.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.request.status == 401) {
          enqueueSnackbar('Session Expired, Login Again', { variant: 'error' });
          localStorage.removeItem('access-token');
          navigate(`/instructor/login`);
        }
        // if (error.request.status >= 500)
        //   enqueueSnackbar(error.request.statusText, { variant: 'error' });
        // else enqueueSnackbar(error.response.data.detail, { variant: 'error' });
      });
  }, []);

  React.useEffect(() => {
    axios
      .get(`/users/instructor/stats`, {
        // params: { instructor_id: `${details.email}` },
        params: { instructor_id: 'f20190128@pilani.bits-pilani.ac.in' },
        config
      })
      .then((response) => {
        console.log(response);
        setStatistics(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.request.status == 401) {
          enqueueSnackbar('Session Expired, Login Again', { variant: 'error' });
          localStorage.removeItem('access-token');
          navigate(`/instructor/login`);
        }
      });
  }, []);

  React.useEffect(() => {
    axios
      .get(`/users/instructor/request`, {
        params: { instructor: 'f20190128@pilani.bits-pilani.ac.in' },
        config
      })
      .then((response) => {
        console.log(response);
        setUserdata(response.data);
        console.log(userdata);
      })
      .catch((error) => {
        console.log(error);
        if (error.request.status == 401) {
          enqueueSnackbar('Session Expired, Login Again', { variant: 'error' });
          localStorage.removeItem('access-token');
          navigate(`/instructor/login`);
        }
      });
  }, []);

  return (
    <div style={{backgroundColor: '#FAF9F5', margin:'0'}}>
      <TopBar title={'Dashboard'} />
      <div className="heading">
        <Typography variant="h4" marginTop={3} marginBottom={4}>
          Hello, {details.first_name} {details.last_name}
        </Typography>
      </div>
      {/* <Stack direction="row">
        <Typography variant="h5" marginBottom={2} marginTop={2} marginLeft={1}>
          User Request Pending
        </Typography>
        <Typography variant="h5" marginBottom={2} marginTop={2} marginLeft={76}>
          Analytics
        </Typography>
      </Stack> */}

      <Stack direction="row">

        <Card style={cardStyle}>
          <CardContent>

            <Typography gutterBottom variant="h4" component="div" style={{ fontFamily: 'Inter', fontSize: 24, margin:'10px' }}>
              Statistics
            </Typography>

            <Stack direction="row">
              <Card style={innerCardStyle}>
                <Typography variant="body1" style={{ fontFamily: 'Inter', fontSize: 14, margin:'10px' }}>
                  Prescriptions
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Inter', fontSize: 48, color:'#DA6B3F', margin:'10px' }}>
                  {statistics.prescription_count}
                </Typography>
              </Card>
              <Card style={innerCardStyle}>
                <Typography variant="body1" style={{ fontFamily: 'Inter', fontSize: 14, margin:'10px' }}>
                  Videos
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Inter', fontSize: 48, color:'#DA6B3F', margin:'10px' }}>
                  {statistics.video_count}
                </Typography>
              </Card>
            </Stack>

            <Stack direction="row">
              <Card style={innerCardStyle}>
                <Typography variant="body1" style={{ fontFamily: 'Inter', fontSize: 14, margin:'10px' }}>
                  Users
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Inter', fontSize: 48, color:'#DA6B3F', margin:'10px' }}>
                  {statistics.active_users_count}
                </Typography>
              </Card>
              <Card style={innerCardStyle}>
                <Typography variant="body1" style={{ fontFamily: 'Inter', fontSize: 14, margin:'10px' }}>
                  Poses
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Inter', fontSize: 48, color:'#DA6B3F', margin:'10px' }}>
                  {statistics.prescription_count}
                </Typography>
              </Card>
            </Stack>

          </CardContent>

        </Card>

        <Card style={userRequestsCard}>
          <CardContent>

            <Typography gutterBottom variant="h4" component="div" style={{ fontFamily: 'Inter', fontSize: 24, margin:'10px' }}>
              User Requests Pending
            </Typography>
            <div
              style={{
                marginTop:'20px',
                height: 260,
                maxHeight: 260,
                width: '100%',
                overflow: 'auto'
              }}>
              <DataGrid
                // rows={userdata.new_users}
                rows={rows}
                columns={columns}
                pageSize={4}
                getRowStyle={() => ({
                  "& .MuiDataGrid-cell": {
                    fontSize: '30px' // Set the font size of all cells
                  },
                  borderBottom: '2px solid rgba(0, 0, 0, 0.1)'
                })}
              />
            </div>
          </CardContent>
        </Card>
      </Stack>
      <Stack direction="row">
        <Typography variant="h5" marginBottom={2} marginTop={2} marginLeft={1}>
          Recent Chats
        </Typography>
        <Typography variant="h5" marginBottom={2} marginTop={2} marginLeft={88}>
          Users
        </Typography>
      </Stack>
      <Stack direction="row">
        <Card style={cardStyle}>
          <CardContent>
            <div style={{ height: 400, maxHeight: 400, width: '100%', overflow: 'auto' }}>
              <DataGrid rows={userdata} columns={chat_columns} pageSize={9} rowsPerPageOptions={[9]} />
            </div>
          </CardContent>
        </Card>

        <Card style={cardStyle}>
          <CardContent>
            <div style={{ height: 400, maxHeight: 400, width: '100%', overflow: 'auto' }}>
              <DataGrid
                // rows={userdata.current_users}
                rows={userdata}
                columns={user_columns}
                pageSize={9}
                rowsPerPageOptions={[9]}
              />
            </div>
          </CardContent>
        </Card>
      </Stack>
    </div>
  );
};
export default Dashboard;
