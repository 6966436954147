import * as React from 'react';
import TopBar from '../../Components/TopBar/TopBar';

/* CSS */
import styles from '../../css/Instructor/Prescription.module.css';

/* MUI */
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { useNavigate } from 'react-router-dom';

// Axios
import axios from '../../axios';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid rgba(0,0,0,0.5)',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'rgba(0,0,0,0.8)'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch'
    }
  }
}));

const Prescription = () => {
  const navigate = useNavigate();

  const [name, setName] = React.useState('');
  const [prescription, setPrescription] = React.useState([]);

  const handleChange = (val) => {
    setName(val);
    console.log(name);
  };

  React.useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem(`access-token`)}` }
    };

    axios
      .get('/content/prescriptions/', config)
      .then((res) => {
        console.log(res.data);
        setPrescription(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleRowClick = (id) => {
    navigate(`/instructor/prescription/edit/${id}`);
  };

  const TableRowComponent = (props) => {
    return (
      <div className={styles.tableRow} onClick={() => handleRowClick(props.id)}>
        <div>{props.id} .</div>
        <div style={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}>
          <div
            style={{
              width: '45%',
              height: '100%',
              backgroundColor: 'pink',
              marginRight: '5%'
            }}></div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '50%',
              height: '100%',
              justifyContent: 'space-around',
              textAlign: 'left'
            }}>
            <div style={{ width: '100%', textAlign: 'left' }}>
              <b>{props.category}</b>
            </div>
            <div style={{ width: '100%', textAlign: 'left' }}>{props.shortDesc}</div>
          </div>
        </div>
        <div>
          <div style={{ width: '100%' }}>15 Videos</div>
          <div style={{ width: '100%' }}>24 Minutes</div>
          <div style={{ width: '100%' }}>15 Users</div>
        </div>
        <div>View Analytics</div>
      </div>
    );
  };
  return (
    <div>
      <TopBar title={'My Prescription'} />
      <div className={styles.topRow}>
        <Button variant="contained" onClick={() => navigate('/instructor/prescription/new')}>
          Add New Prescription
        </Button>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            onChange={(e) => handleChange(e.target.value)}
          />
        </Search>
      </div>
      <div id={styles.table}>
        <div className={styles.tableHead}>
          <div></div>
          <div>Prescription</div>
          <div>Details</div>
          <div>Analytics</div>
        </div>

        {prescription.map((item, idx) => (
          <TableRowComponent
            key={idx}
            id={item.id}
            category={item.name}
            shortDesc={item.short_desc}
          />
        ))}
      </div>
    </div>
  );
};
export default Prescription;
