import axios from 'axios';

const baseURL = 'https://cognixai.com/back';

const instance = axios.create({
  baseURL: baseURL
});

const instanceWithoutHeaders = axios.create({
  baseURL: baseURL
});

export { instance as default, instanceWithoutHeaders };
export { baseURL };
