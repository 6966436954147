import { combineReducers } from 'redux';
// import * as actionTypes from '../actions/actionTypes';

import BlogDataReducer from './blogDataReducer';

const appReducer = combineReducers({
  blogDataUpdate: BlogDataReducer
});

const rootReducer = (state, action) => {
  // if (action.type === actionTypes.LOGOUT) {
  //   state = undefined;
  // }

  return appReducer(state, action);
};

export default rootReducer;
