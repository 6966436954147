const CognixLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="55"
      height="45"
      viewBox="0 0 140 120"
      preserveAspectRatio="none">
      <metadata>Created by potrace 1.16, written by Peter Selinger 2001-2019</metadata>
      <g transform="translate(0,160) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <path d="M400 1425 c-17 -20 -3 -55 22 -55 11 0 64 -38 120 -87 56 -49 114 -100 131 -114 l31 -26 -33 -16 -33 -17 -68 58 c-44 37 -69 66 -72 82 -2 20 -9 25 -33 25 -25 0 -30 -4 -33 -28 -3 -27 -1 -30 48 -41 14 -4 51 -30 82 -59 l58 -52 -19 -28 -18 -28 -179 152 c-98 84 -180 160 -182 171 -9 48 -72 41 -72 -8 0 -22 30 -41 49 -30 5 3 89 -62 187 -146 99 -83 181 -152 183 -154 2 -2 0 -19 -3 -39 l-7 -35 -84 72 c-47 39 -85 79 -85 88 0 38 -52 55 -69 22 -13 -24 4 -52 31 -52 11 0 64 -37 117 -81 72 -62 99 -91 109 -120 18 -50 82 -105 137 -117 54 -11 210 -140 231 -190 10 -26 19 -33 35 -30 31 4 46 32 30 52 -8 9 -22 16 -33 16 -11 0 -58 33 -104 72 l-85 73 33 11 c38 13 23 23 175 -104 70 -58 113 -101 115 -115 4 -29 61 -32 66 -4 5 24 -15 47 -40 47 -11 0 -73 44 -137 97 -64 53 -118 98 -120 100 -6 4 30 43 40 43 17 0 149 -124 149 -140 0 -25 25 -43 50 -35 12 4 20 15 20 29 0 27 -24 51 -40 41 -13 -8 -159 115 -160 136 0 8 6 24 14 34 12 17 23 10 159 -106 80 -68 146 -132 146 -141 2 -41 71 -40 71 1 0 24 -25 45 -45 37 -10 -4 -67 39 -168 126 -134 115 -153 136 -159 169 -18 99 -98 169 -193 169 -32 0 -53 14 -171 117 -74 65 -132 123 -130 130 6 15 -20 43 -38 43 -8 0 -19 -7 -26 -15z m440 -317 c105 -53 131 -182 54 -267 -45 -50 -81 -65 -148 -59 -97 8 -156 72 -156 170 0 136 130 216 250 156z" />
        <path d="M712 1024 c-34 -23 -45 -60 -31 -101 20 -63 108 -78 149 -25 62 78 -37 183 -118 126z" />
        <path d="M796 1421 c-3 -4 -34 -11 -68 -15 -67 -7 -127 -23 -142 -37 -17 -15 0 -20 29 -9 16 6 56 15 89 19 49 7 66 6 89 -7 21 -13 31 -14 45 -5 40 25 174 -46 250 -132 58 -66 84 -122 69 -147 -10 -16 -8 -22 12 -39 20 -17 24 -28 24 -87 1 -37 -2 -84 -6 -104 -5 -23 -4 -38 1 -38 18 0 25 35 27 136 1 56 5 106 9 112 4 7 -3 22 -16 34 -13 13 -32 41 -43 62 -35 70 -120 152 -197 191 -39 19 -76 35 -83 35 -6 0 -20 9 -30 20 -19 21 -50 27 -59 11z" />
        <path d="M770 1240 c-12 -7 -8 -10 15 -10 40 0 122 -39 168 -80 20 -17 50 -60 67 -93 l29 -62 1 45 c0 83 -55 162 -137 197 -37 15 -121 17 -143 3z" />
        <path d="M317 1043 c-10 -10 -8 -163 2 -199 5 -17 5 -37 1 -45 -11 -16 6 -49 25 -49 7 0 17 -9 20 -20 10 -33 114 -139 164 -167 25 -15 56 -38 69 -51 16 -16 28 -21 40 -16 9 4 52 6 94 5 86 -3 188 16 188 34 0 9 -13 9 -52 -2 -32 -9 -81 -13 -125 -10 -61 3 -75 8 -91 27 -15 18 -22 21 -37 12 -26 -14 -82 15 -146 75 -64 60 -93 106 -83 135 5 17 0 27 -22 45 -28 22 -29 25 -31 124 -2 102 -4 114 -16 102z" />
        <path d="M480 867 c0 -61 19 -102 70 -152 46 -45 118 -68 175 -55 49 11 56 20 14 20 -82 1 -185 79 -231 175 l-28 60 0 -48z" />
      </g>
    </svg>
  );
};

export default CognixLogo;
