import React from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { TextField, Typography, Box, Divider, Button, ButtonGroup } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useSnackbar } from 'notistack';

import {
  ArrowBackIos,
  ArrowForwardIos,
  Camera,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  Save,
  UploadFile
} from '@mui/icons-material';

import { Dialog } from 'primereact/dialog';
import { TrashCanIcon } from '@icons/material';

import api from '../../axios';

const CreateYogaPose = () => {
  const vidRef = React.useRef(null);
  const canvasRef = React.useRef();

  const navigate = useNavigate();
  // const params = useParams();
  const { enqueueSnackbar } = useSnackbar();

  // if (params.id) {
  //   React.useEffect(() => {
  //     api
  //       .get(`/content/videos/${params.id}`)
  //       .then((res) => {
  //         console.log(res.data);
  //         setURI(res.data);
  //         setImages(res.data);
  //         setName(res.data);
  //         setFile(res.data);
  //         setVisible(res.data);
  //         setKeyframes(res.data);
  //         setDescription(res.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }, [params.id]);
  // }

  const [URI, setURI] = React.useState();
  const [images, setImages] = React.useState([]);
  const [name, setName] = React.useState('');
  const [file, setFile] = React.useState();
  const [visible, setVisible] = React.useState(false);
  const [currentID, setCurrentID] = React.useState(0);
  const [currentTimestamp, setCurrentTimestamp] = React.useState(0);
  const [keyframes, setKeyframes] = React.useState([]);
  const [description, setDescription] = React.useState('');

  const handlePlay = () => {
    vidRef.current.play();
  };

  const handlePause = () => {
    vidRef.current.pause();
  };

  const nextFrame = () => {
    handlePause();
    vidRef.current.currentTime += 1 / 30;
  };

  const prevFrame = () => {
    handlePause();
    vidRef.current.currentTime -= 1 / 30;
  };
  const forward5 = () => {
    handlePause();
    vidRef.current.currentTime += 5;
  };

  const backward5 = () => {
    handlePause();
    vidRef.current.currentTime -= 5;
  };

  const captureFrame = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.drawImage(vidRef.current, 0, 0, canvas.width, canvas.height);

    const imageDataUrl = canvas.toDataURL('image/png');
    const arr = [...images];
    const frames = [...keyframes];
    frames.push({ offset: vidRef.current.currentTime });
    setKeyframes(frames);
    arr.push({ img: imageDataUrl, timestamp: vidRef.current.currentTime });
    arr.sort((a, b) => a.timestamp - b.timestamp);
    setImages(arr);
    enqueueSnackbar('Pose Captured Sucessfully!', { variant: 'success' });
  };

  React.useEffect(() => {
    console.log(images);
  }, [images]);

  const deleteFrame = (idx) => {
    let temp = [];
    let tempFrames = [];
    for (let i = 0; i < images.length; i++) {
      if (i !== idx) {
        temp.push(images[i]);
        tempFrames.push(keyframes[i]);
      }
    }

    setImages(temp);
    setKeyframes(tempFrames);
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    const videoString = URL.createObjectURL(file);
    setFile(file);
    setURI(videoString);
  };

  function replacer(key, value) {
    if(value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries()), // or with spread: value: [...value]
      };
    } else {
      return value;
    }
  }

  const handlePost = () => {
    const newKeyframes = JSON.stringify(keyframes, replacer);
    console.log('Chiradi Test',newKeyframes)
    const data = {
      name: name,
      short_desc: description,
      category: 'BACK PAIN',
      duration: 3,
      keyframes: newKeyframes,
      file: file,
    };
    console.log(data);

    api
      .post('/content/videos/', data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(`access-token`)}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        console.log(res.data);
        enqueueSnackbar('Yoga Pose Created Sucessfully!', { variant: 'success' });
      })
      .catch((error) => {
        console.log(error);
        if (error.request.status == 401) {
          enqueueSnackbar('Session Expired, Login Again', { variant: 'error' });
          localStorage.removeItem('access-token');
          navigate(`/instructor/login`);
        }
      });
  };

  return (
    <div>
      <Typography sx={{ mt: 6, fontWeight: 'medium' }} variant="h4">
        ADD YOGA POSE
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          fullWidth
          variant="outlined"
          label="Add pose name"
          focused
          value={name}
          sx={{ mt: 4, height: '100%' }}
          onChange={(e) => setName(e.target.value)}
        />
        <EditIcon sx={{ ml: 3, mr: 5, fontSize: 30, mt: 4 }} />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <TextField
          fullWidth
          variant="outlined"
          label="Add short description"
          focused
          value={description}
          sx={{ mt: 4, height: '100%' }}
          onChange={(e) => setDescription(e.target.value)}
          minRows={3}
          multiline
        />
        <EditIcon sx={{ ml: 3, mr: 5, fontSize: 30, mt: 4 }} />
      </Box>

      <Divider />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '100%',
          p: 2,
          height: 550
        }}>
        <Box sx={{ width: '60%' }}>
          <Box>
            {URI ? (
              <video controls style={{ width: '100%', height: 400 }} ref={vidRef}>
                <source src={URI} type="video/mp4" />
              </video>
            ) : (
              <Typography variant="h6" align="center">
                UPLOAD A VIDEO FIRST
              </Typography>
            )}
          </Box>

          <Box sx={{ margin: '10px auto', display: 'flex', justifyContent: 'center' }}>
            <ButtonGroup fullWidth>
              <Button sx={{ pl: 4, pr: 4, pt: 1, pb: 1 }}>
                <KeyboardDoubleArrowLeft onClick={backward5} />
              </Button>

              <Button sx={{ pl: 4, pr: 4, pt: 1, pb: 1 }} onClick={prevFrame}>
                <ArrowBackIos />
              </Button>

              <Button sx={{ pl: 4, pr: 4, pt: 1, pb: 1 }} onClick={handlePlay}>
                <PlayArrowIcon />
              </Button>

              <Button sx={{ pl: 4, pr: 4, pt: 1, pb: 1 }} onClick={handlePause}>
                <PauseIcon />
              </Button>

              <Button sx={{ pl: 4, pr: 4, pt: 1, pb: 1 }} onClick={nextFrame}>
                <ArrowForwardIos />
              </Button>

              <Button sx={{ pl: 4, pr: 4, pt: 1, pb: 1 }}>
                <KeyboardDoubleArrowRight onClick={forward5} />
              </Button>
            </ButtonGroup>
          </Box>

          <Box sx={{ margin: '10px auto', display: 'flex', justifyContent: 'center' }}>
            <ButtonGroup fullWidth>
              <Button fullWidth startIcon={<Camera />} onClick={captureFrame}>
                Capture Frame
              </Button>

              <Button
                fullWidth
                startIcon={<UploadFile />}
                aria-label="upload picture"
                component="label">
                <input hidden accept="video/mp4" type="file" onChange={handleUpload} />
                Upload A Video
              </Button>

              <Button
                fullWidth
                startIcon={<Save />}
                aria-label="upload picture"
                component="label"
                onClick={handlePost}>
                Save
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
        <Box sx={{ width: '35%', height: '100%', overflowY: 'auto' }}>
          <Typography align="center" variant="h6">
            Keyframes
          </Typography>
          {images.map((item, idx) => {
            // console.log({item, idx});

            return <Box
              key={idx}
              sx={{
                width: '100%',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
              <img
                src={item.img}
                alt=""
                style={{ width: '100%', cursor: 'pointer' }}
                onClick={() => {
                  setVisible(true);
                  setCurrentTimestamp(item.timestamp);
                  setCurrentID(idx);
                }}
              />
              <Dialog
                header={'Frame-' + currentID}
                visible={visible}
                style={{ width: '50vw' }}
                onHide={() => setVisible(false)}>
                <Typography sx={{ mb: 2, mt: 2 }}>Timestamp : {currentTimestamp} seconds</Typography>

                <Button
                  color="error"
                  variant="outlined"
                  fullWidth
                  endIcon={<TrashCanIcon />}
                  onClick={() => deleteFrame(currentID)}>
                  Delete
                </Button>
              </Dialog>
            </Box>
          })}
        </Box>

        <canvas ref={canvasRef} style={{ height: '100%', width: '100%', display: 'none' }} />
      </Box>
    </div>
  );
};

export default CreateYogaPose;
