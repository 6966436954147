import TopBar from '../../Components/TopBar/TopBar';
import { Typography } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
 const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'Name', headerName: 'First name', width: 180 },
  { field: 'prescription', headerName: 'Prescription', width: 150 },
  {
    field: 'date_of_req',
    headerName: 'Date Of Request',
    type: 'number',
    width: 150,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    
    width: 740,
 /*    valueGetter: (params) =>
      `${params.row.firstName || ''} ${params.row.lastName || ''}`, */
  },
];
//dummy data
const rows = [
  { id: 1, prescription: 'BackPain', Name: 'Jon', age: 35 },
  { id: 2, prescription:'BackPain', Name: 'Cersei', age: 42 },
  { id: 3, prescription:'BackPain', Name: 'Jaime', age: 45 },
  { id: 4, prescription: 'NeckPain', Name: 'Arya', age: 16 },
  { id: 5, prescription:'NeckPain', Name: 'Daenerys', age: null },
  { id: 6, prescription: 'NeckPain', Name: null, age: 150 },
  { id: 7, prescription:'BackPain', Name: 'Ferrara', age: 44 },
  { id: 8, prescription: 'BackPain', Name: 'Rossini', age: 36 },
  { id: 9, prescription:'NeckPain', Name: 'Harvey', age: 65 },
];

const Users = () => {
  return (
    <div>
      <TopBar title={'Users'} />
      <div><Typography variant ='h5'>Net Users: </Typography></div>
      <div ><Typography variant = 'h6' marginTop={3} marginBottom ={2}>User Request Remaining</Typography></div>
      <div style={{height: 400 , width: '100%'}}>
       <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        
      /> 
      </div>
      <div><Typography variant = 'h6' marginTop={3} marginBottom = {2}>Current Users</Typography></div>
      <div style={{height: 400 , width: '100%'}}>
       <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
     
      /> 
      </div>
    </div>
  );
};
export default Users;
