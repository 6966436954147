import * as actionTypes from '../actions/actionTypes';

const initialState = {
  reactComponent: null
};

const reducer = (state = initialState, action = null) => {
  switch (action.type) {
    case actionTypes.BLOG_DATA_UPDATE: {
      return {
        ...state,
        reactComponent: action.reactComponent
      };
    }
    default:
      return state;
  }
};

export default reducer;
