import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import axios from './../../axios';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';

/* MUI */
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';

/* CSS */
import styles from '../../css/Instructor/Login.module.css';

const left_img = process.env.PUBLIC_URL + '/assets/images/InstructorLogin.png';

const InstructorLogin = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [details, setDetails] = React.useState({
    email: '',
    password: ''
  });

  /* GOOGLE Login */
  const onLoginSuccess = (credentialResponse) => {
    // console.log(credentialResponse);
    console.log(credentialResponse);
    axios
      .post(`/users/login/`, {
        user_type: 'I',
        login_type: 'googleLogin',
        token: credentialResponse.credential
      })
      .then((response) => {
        console.log(response);
        localStorage.setItem('access-token', response.data.access);
        enqueueSnackbar('Login Successful!', { variant: 'success' });
        navigate('/instructor');
      })
      .catch((error) => {
        console.log(error);
        if (error.request.status >= 500)
          enqueueSnackbar(error.request.statusText, { variant: 'error' });
        else enqueueSnackbar('Error in Login', { variant: 'error' });
      });
  };

  const onLoginFailure = () => {
    enqueueSnackbar('Login Failed!', 'error');
  };
  // console.log(process.env.REACT_APP_CLIENT_ID);

  /* Normal Login */
  const normalLogin = () => {
    console.log('login', details.email, details.password);
    if (!validator.isEmail(details.email)) {
      enqueueSnackbar('Please enter correct Email ID!', { variant: 'error' });
      return;
    } else if (details.password == '') {
      enqueueSnackbar('Password can not be null', { variant: 'error' });
      return;
    }
    axios
      .post(`/users/login/`, {
        user_type: 'I',
        login_type: 'email',
        email: details.email,
        password: details.password
      })
      .then((response) => {
        console.log(response);
        localStorage.setItem('access-token', response.data.access);
        enqueueSnackbar('Login Successful!', { variant: 'success' });
        navigate('/instructor');
      })
      .catch((error) => {
        console.log(error);
        if (error.request.status >= 500)
          enqueueSnackbar(error.request.statusText, { variant: 'error' });
        else enqueueSnackbar('Error in Login! ' + error.response.data.detail, { variant: 'error' });
      });
    // navigate('/instructor');
  };

  return (
    <div className={styles.outerdiv}>
      <div className={styles.leftouter}>
        <div
          className={styles.leftinner}
          style={{
            backgroundImage: `url(${left_img})`,
            backgroundPosition: 'bottom',
            backgroundRepeat: 'no-repeat'
          }}></div>
      </div>
      <div className={styles.rightouter}>
        <div className={styles.head}>
          <div className={styles.head1}>Welcome Back</div>
          <div className={styles.head2}>Instructors!</div>
        </div>
        <div className={styles.textField}>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            sx={{ width: '100%', mt: 3, mb: 3 }}
            value={details.email}
            onChange={(e) => setDetails((prev) => ({ ...prev, email: e.target.value }))}
          />
          <TextField
            id="outlined-basic"
            label="Password"
            type="password"
            variant="outlined"
            sx={{ width: '100%' }}
            value={details.password}
            onChange={(e) => setDetails((prev) => ({ ...prev, password: e.target.value }))}
          />
          <p className={styles.forgotPassword} onClick={() => navigate('/forgot-password')}>
            <u>Forgot Password</u>
          </p>
          <div className={styles.loginButton} onClick={() => normalLogin()}>
            Login
          </div>
          <div className={styles.googleSigninOuter}>
            <div className={styles.googleSignin}>
              <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
                <GoogleLogin
                  onSuccess={(credentialResponse) => onLoginSuccess(credentialResponse)}
                  onFailure={onLoginFailure}
                  // type = 'icon'
                  shape="circle"
                />
              </GoogleOAuthProvider>
            </div>
          </div>
        </div>
        <div className={styles.signupOuter}>
          New to the family?&nbsp;&nbsp;
          <span className={styles.signUpLink} onClick={() => navigate('/instructor/signup')}>
            <u>Sign Up</u>
          </span>
        </div>
      </div>
    </div>
  );
};

export default InstructorLogin;
