import React from 'react';

import { Paper, Typography } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary
}));

const BlogSidebarCard = (props) => {
  return (
    <Link to={`/blog/view/${props.id}`}>
      <Item sx={{ width: '99%', mb: 2, minHeight: 100 }}>
        <img
          src={props.image}
          alt=""
          style={{
            height: 60,
            width: 60,
            float: 'left',
            marginRight: 10,
            borderRadius: 10
          }}
        />
        <Typography>{props.title}</Typography>
        <Typography>{props.content.substring(0, 20)}</Typography>
      </Item>
    </Link>
  );
};

export default BlogSidebarCard;
