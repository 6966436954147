import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

/* CSS */
import './../../css/Landing/Landing.css';
// import './assets/css/style.css';
// import './assets/vendor/bootstrap/css/bootstrap.min.css'
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './assets/vendor/boxicons/css/boxicons.min.css';
// import './assets/vendor/glightbox/css/glightbox.min.css'
import './assets/vendor/remixicon/remixicon.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

/* IMAGES */
import CognixLogo from './assets/img/logo.png';
import MobileIMG from './assets/img/mobile1.png';
import team1IMG from './assets/img/team/team-1.jpg';
import team2IMG from './assets/img/team/team-2.jpg';
import team3IMG from './assets/img/team/team-3.jpg';
import team4IMG from './assets/img/team/team-4.jpg';
import postureIMG1 from './assets/img/posture_matching3.png';
import avatarVid from './assets/img/avatar.mp4';
import carvid1 from './assets/video/asanavid1.mp4';
import carvid2 from './assets/video/asanavid2.mp4';

import Paper from '@mui/material/Paper';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

import api from '../../axios';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#eaffd9',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary
}));

export function Header() {
  let navigate = useNavigate();

  const [blog, setBlog] = React.useState({});

  React.useEffect(() => {
    api
      .get('/content/blogs')
      .then((res) => {
        console.log(res.data[0]);
        setBlog(res.data[0]);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center">
        <h1 className="logo me-auto">
          <a href="#" className="logo">
            <img src={CognixLogo} alt="" className="img-fluid" style={{ marginRight: '10px' }} />
          </a>
          <a href="#" style={{ color: 'white' }}>
            CogniX
          </a>
        </h1>
        <nav id="navbar" className="navbar">
          <ul>
            <li>
              <a className="nav-link scrollto active" href="#landing_hero">
                Home
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#landing_cognixintro">
                About
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#landing_yogigintro">
                Yogi-G
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#team">
                Team
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#footer">
                Contact
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" onClick={() => navigate(`/blog/view/${blog.id}`)}>
                Blogs
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" onClick={() => navigate(`/instructor`)}>
                Instructors
              </a>
            </li>
            {/* <li>
              <a
                className='nav-link scrollto'
                onClick={() => navigate("/instructor")}
              >
                Instructors
              </a>
            </li> */}
            <li>
              <a className="getstarted scrollto" onClick={() => navigate('/')}>
                Get Started
              </a>
            </li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>
      </div>
    </header>
  );
}

const Landing = () => {
  React.useEffect(() => {
    AOS.init({
      duration: 500,
      easing: 'ease-in-out',
      once: true,
      mirror: false
    });
    AOS.refresh();
  }, []);

  React.useEffect(() => {
    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      } else {
        return document.querySelector(el);
      }
    };

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      let selectEl = select(el, all);
      if (selectEl) {
        if (all) {
          selectEl.forEach((e) => e.addEventListener(type, listener));
        } else {
          selectEl.addEventListener(type, listener);
        }
      }
    };

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
      el.addEventListener('scroll', listener);
    };

    /**
     * Navbar links active state on scroll
     */
    let navbarlinks = select('#navbar .scrollto', true);
    const navbarlinksActive = () => {
      let position = window.scrollY + 200;
      navbarlinks.forEach((navbarlink) => {
        if (!navbarlink.hash) return;
        let section = select(navbarlink.hash);
        if (!section) return;
        if (position >= section.offsetTop && position <= section.offsetTop + section.offsetHeight) {
          navbarlink.classList.add('active');
        } else {
          navbarlink.classList.remove('active');
        }
      });
    };
    window.addEventListener('load', navbarlinksActive);
    onscroll(document, navbarlinksActive);

    /**
     * Scrolls to an element with header offset
     */
    const scrollto = (el) => {
      let header = select('#header');
      let offset = header.offsetHeight;

      let elementPos = select(el).offsetTop;
      window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth'
      });
    };

    /**
     * Toggle .header-scrolled className to #header when page is scrolled
     */
    let selectHeader = select('#header');
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add('header-scrolled');
        } else {
          selectHeader.classList.remove('header-scrolled');
        }
      };
      window.addEventListener('load', headerScrolled);
      onscroll(document, headerScrolled);
    }

    /**
     * Back to top button
     */
    let backtotop = select('.back-to-top');
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add('active');
        } else {
          backtotop.classList.remove('active');
        }
      };
      window.addEventListener('load', toggleBacktotop);
      onscroll(document, toggleBacktotop);
    }

    /**
     * Mobile nav toggle
     */
    on('click', '.mobile-nav-toggle', function () {
      select('#navbar').classList.toggle('navbar-mobile');
      this.classList.toggle('bi-list');
      this.classList.toggle('bi-x');
    });

    /**
     * Mobile nav dropdowns activate
     */
    on(
      'click',
      '.navbar .dropdown > a',
      function (e) {
        if (select('#navbar').classList.contains('navbar-mobile')) {
          e.preventDefault();
          this.nextElementSibling.classList.toggle('dropdown-active');
        }
      },
      true
    );

    /**
     * Scrool with ofset on links with a className name .scrollto
     */
    on(
      'click',
      '.scrollto',
      function (e) {
        if (select(this.hash)) {
          e.preventDefault();

          let navbar = select('#navbar');
          if (navbar.classList.contains('navbar-mobile')) {
            navbar.classList.remove('navbar-mobile');
            let navbarToggle = select('.mobile-nav-toggle');
            navbarToggle.classList.toggle('bi-list');
            navbarToggle.classList.toggle('bi-x');
          }
          scrollto(this.hash);
        }
      },
      true
    );

    /**
     * Scroll with ofset on page load with hash links in the url
     */
    window.addEventListener('load', () => {
      if (window.location.hash) {
        if (select(window.location.hash)) {
          scrollto(window.location.hash);
        }
      }
    });
  }, []);

  return (
    <div>
      {/* <div className='page1_outer' style={{opacity:'0.9'}}>
            <img src={SceneImg} className='page1_scene_img'/>
            <img src={SaintImg} className='page1_saint_img'/>
        </div> */}
      <Header />
      <Hero />
      <div id="mainpage">
        <CognixIntro />
        <YogigIntro />
        <YogigFeatures />
        {/* <FeatureCognix/> */}
        {/* <WhyCognix /> */}
        <DownloadApp />
        {/* <AboutUS/> */}
        <Team />
        {/* <Contact/> */}
        <Footer />
      </div>
    </div>
  );
};

function Hero() {
  let navigate = useNavigate();

  const [blog, setBlog] = React.useState({
    title: '',
    content: '',
    image: '',
    id: ''
  });

  React.useEffect(() => {
    api
      .get('/content/blogs')
      .then((res) => {
        console.log(res.data[0]);
        setBlog(res.data[0]);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div style={{ background: '#1B2C3F', paddingBottom: 20 }}>
      <section id="landing_hero">
        <div>
          <h1>Welcome to</h1>
          <h1 className="landing_hero_cognix">CogniX</h1>
          <p>Providing cutting-edge solutions for tracking, detection, and feature matching</p>

          <div className="landing_hero_button_group">
            <div
              className="landing_hero_button landing_iconHover"
              onClick={() => navigate('/signup')}>
              <div>Get Started</div>
            </div>
            <div className="landing_hero_button landing_iconHover" style={{ marginLeft: '5vw' }}>
              <div>
                <i className="bi bi-play-circle" style={{ marginRight: '5%' }} />
                Video
              </div>
            </div>
          </div>
        </div>
        <div className="landing_hero_heroimg"></div>
      </section>
      <div style={{ position: 'relative', top: '-9vw' }}>
        <Link to={`/blog/view/${blog.id}`}>
          <Item
            sx={{
              width: '70%',
              margin: '0 auto 0 15%',
              pb: 2,
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              background: '#87D5DF'
            }}>
            <div style={{ width: 60 }}>
              <img src={blog.image} alt="" style={{ width: 60, height: 60 }} />
            </div>
            <div style={{ width: '80%' }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {blog.title}
              </Typography>
              <Typography>{blog.content.substring(0, 100)} ...</Typography>
            </div>
          </Item>
        </Link>
      </div>
    </div>
  );
}

function CognixIntro() {
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   speed: 2000,
  //   autoplaySpeed: 2000,
  //   cssEase: 'linear'
  // };

  return (
    <>
      <section id="landing_cognixintro" style={{ paddingBottom: '40px' }}>
        <h3 className="landing_cognixintro_heading">
          Motion Analysis <span style={{ color: '#45a29e' }}>Revolutionized</span>
        </h3>
        {/* <h1 className='landing_cognixintro_heading'>Motion analysis</h1> */}
        <p className="landing_cognixintro_text">
          CogniX provides cutting-edge technology revolutionizing motion analytics. We are currently
          focusing on the development of a privacy-preserving AI-assisted Yoga platform for
          practitioners and instructors. Our platform will enable Yoga instructors to connect and
          teach practitioners globally. We also do advanced research in machine learning, deep
          learning, computer vision, multimodal artificial intelligence, human motion, behavior
          understanding, and pattern recognition to develop custom cognitive computing solutions.
        </p>
      </section>
      <div className="landing_cognixintro_carousel">
        <Slider
          style={{ width: '90%', margin: 'auto' }}
          dots={true}
          infinite={true}
          slidesToShow={2}
          slidesToScroll={1}
          autoplay={true}
          autoplaySpeed={3000}
          pauseOnHover={false}>
          <div>
            <video
              className="landing_cognixintro_carousel_video"
              autoPlay={true}
              loop
              muted={true}
              src={carvid2}
              type="video/mp4"
            />
          </div>
          <div>
            <video
              className="landing_cognixintro_carousel_video"
              autoPlay={true}
              loop
              muted={true}
              src={avatarVid}
              type="video/mp4"
            />
          </div>
          <div>
            <video
              className="landing_cognixintro_carousel_video"
              autoPlay={true}
              loop
              muted={true}
              src={carvid1}
              type="video/mp4"
            />
          </div>
        </Slider>
      </div>
    </>
  );
}

function YogigIntro() {
  return (
    <section id="landing_yogigintro">
      <div className="landing_yogigintro_inner">
        <div className="landing_yogigintro_left">
          <h1>Yogi-G: The Future of Yoga</h1>
          <p>
            About 300 million people practice yoga in the world and getting{' '}
            <b>personalized guidance at home</b> is not feasible with limited number of Yoga
            Instructors.
          </p>
          <h4>Existing Yoga Apps do not provide</h4>
          <ul>
            <li>personalized Feedback and Guidance</li>
            <li>preserve the privacy of the Practitioners </li>
            <li>provide accurate Pose matching </li>
            <li>allow instructors to define customized Asanas.</li>
          </ul>
        </div>
        <div className="landing_yogigintro_right">
          <img src={postureIMG1} alt="Posture Matching" className="landing_yogigintro_img" />
          <h5>Taking ancient practice YOGA to the future with AI</h5>
        </div>
      </div>
    </section>
  );
}

function YogigFeatures() {
  return (
    <section id="landing_yogifeatures">
      <h1 className="landing_yogifeatures_heading">
        Yoga Experieces with <span style={{ color: '#45a29e' }}>Yogi-G</span>
      </h1>
      <p className="landing_yogifeatures_text" style={{ marginBottom: '10px' }}>
        Yogi-G is a unique AI assisted Yoga platform
      </p>

      {/* <img src={collageIMG} style={{width:'100%', margin:'2vh 0'}}/> */}

      <div className="landing_yogifeatures_listouter">
        <div className="landing_yogifeatures_item">
          <svg
            style={{ width: '10%', margin: 'auto auto 0 auto' }}
            viewBox="0 0 17 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 0C7.9 0 7 0.9 7 2C7 3.11 7.9 4 9 4C10.11 4 11 3.11 11 2C11 0.9 10.11 0 9 0ZM0 5V7H6V13L0.93 18.07L2.34 19.5L9.06 12.77L13 15.13V19H15V14.57C15 14.21 14.82 13.89 14.5 13.71L11 11.6V7H17V5H0Z"
              fill="black"
            />
          </svg>
          <p>An efficient AI based novel posture matching algoritm</p>
        </div>
        <div className="landing_yogifeatures_item">
          <svg
            style={{ width: '10%', margin: 'auto auto 0 auto' }}
            viewBox="0 0 22 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.83 6L14 9.16C14 9.11 14 9.05 14 9C14 8.20435 13.6839 7.44129 13.1213 6.87868C12.5587 6.31607 11.7956 6 11 6C10.94 6 10.89 6 10.83 6ZM6.53 6.8L8.08 8.35C8.03 8.56 8 8.77 8 9C8 9.79565 8.31607 10.5587 8.87868 11.1213C9.44129 11.6839 10.2044 12 11 12C11.22 12 11.44 11.97 11.65 11.92L13.2 13.47C12.53 13.8 11.79 14 11 14C9.67392 14 8.40215 13.4732 7.46447 12.5355C6.52678 11.5979 6 10.3261 6 9C6 8.21 6.2 7.47 6.53 6.8ZM1 1.27L3.28 3.55L3.73 4C2.08 5.3 0.78 7 0 9C1.73 13.39 6 16.5 11 16.5C12.55 16.5 14.03 16.2 15.38 15.66L15.81 16.08L18.73 19L20 17.73L2.27 0L1 1.27ZM11 4C12.3261 4 13.5979 4.52678 14.5355 5.46447C15.4732 6.40215 16 7.67392 16 9C16 9.64 15.87 10.26 15.64 10.82L18.57 13.75C20.07 12.5 21.27 10.86 22 9C20.27 4.61 16 1.5 11 1.5C9.6 1.5 8.26 1.75 7 2.2L9.17 4.35C9.74 4.13 10.35 4 11 4Z"
              fill="black"
            />
          </svg>
          <p>User privacy protection is ensured through edge computing</p>
        </div>
        <div className="landing_yogifeatures_item">
          <svg
            style={{ width: '10%', margin: 'auto auto 0 auto' }}
            viewBox="0 0 24 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M23 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1L0 19C0 19.2652 0.105357 19.5196 0.292893 19.7071C0.48043 19.8946 0.734784 20 1 20H23C23.2652 20 23.5196 19.8946 23.7071 19.7071C23.8946 19.5196 24 19.2652 24 19V1C24 0.734784 23.8946 0.48043 23.7071 0.292893C23.5196 0.105357 23.2652 0 23 0ZM22 18H20V17H15V18H2V2H22V18ZM10.29 7.71C10.29 7.25648 10.4702 6.82154 10.7908 6.50085C11.1115 6.18016 11.5465 6 12 6C12.95 6 13.71 6.77 13.71 7.71C13.71 8.66 12.95 9.43 12 9.43C11.05 9.43 10.29 8.66 10.29 7.71ZM5.71 9.29C5.71 8.58 6.29 8 7 8C7.34213 8 7.67025 8.13591 7.91217 8.37783C8.15409 8.61975 8.29 8.94787 8.29 9.29C8.29 10 7.71 10.57 7 10.57C6.29 10.57 5.71 10 5.71 9.29ZM15.71 9.29C15.71 8.94787 15.8459 8.61975 16.0878 8.37783C16.3298 8.13591 16.6579 8 17 8C17.3421 8 17.6702 8.13591 17.9122 8.37783C18.1541 8.61975 18.29 8.94787 18.29 9.29C18.29 10 17.71 10.57 17 10.57C16.29 10.57 15.71 10 15.71 9.29ZM20 13.14V14H16H14H10H8H4V13.14C4 12.2 5.55 11.43 7 11.43C7.55 11.43 8.11 11.54 8.6 11.73C9.35 11.04 10.7 10.57 12 10.57C13.3 10.57 14.65 11.04 15.4 11.73C15.89 11.54 16.45 11.43 17 11.43C18.45 11.43 20 12.2 20 13.14Z"
              fill="black"
            />
          </svg>

          <p>
            Yoga classes and personalized audiovisual feedback to 1000s of practitioners at a time
          </p>
        </div>
        <div className="landing_yogifeatures_item">
          <svg
            style={{ width: '10%', margin: 'auto auto 0 auto' }}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17 6C17.56 6 18 6.43 18 7C18 7.26522 17.8946 7.51957 17.7071 7.70711C17.5196 7.89464 17.2652 8 17 8C16.43 8 16 7.55 16 7C16 6.43 16.43 6 17 6ZM0 0V9C0 11.96 2.19 14.5 5.14 14.91C5.76 17.92 8.42 20 11.5 20C12.3536 20 13.1988 19.8319 13.9874 19.5052C14.7761 19.1786 15.4926 18.6998 16.0962 18.0962C16.6998 17.4926 17.1786 16.7761 17.5052 15.9874C17.8319 15.1988 18 14.3536 18 13.5V9.81C19.16 9.39 20 8.29 20 7C20 6.20435 19.6839 5.44129 19.1213 4.87868C18.5587 4.31607 17.7956 4 17 4C16.2044 4 15.4413 4.31607 14.8787 4.87868C14.3161 5.44129 14 6.20435 14 7C14 8.29 14.84 9.4 16 9.81V13.41C16 15.91 14 17.91 11.5 17.91C9.5 17.91 7.82 16.7 7.22 14.9C10 14.3 12 11.8 12 9V0H8V3H10V9C10 10.0609 9.57857 11.0783 8.82843 11.8284C8.07828 12.5786 7.06087 13 6 13C4.93913 13 3.92172 12.5786 3.17157 11.8284C2.42143 11.0783 2 10.0609 2 9V3H4V0H0Z"
              fill="black"
            />
          </svg>
          <p>
            Personalized recommendations based on specific physical or medical condition of the
            practitioner
          </p>
        </div>
        <div className="landing_yogifeatures_item">
          <svg
            style={{ width: '10%', margin: 'auto auto 0 auto' }}
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 0C10.11 0 11 0.89 11 2C11 3.11 10.11 4 9 4C7.89 4 7 3.11 7 2C7 0.89 7.9 0 9 0ZM18 12V10C15.76 10 13.84 9.04 12.4 7.32L11.06 5.72C10.68 5.26 10.12 5 9.53 5H8.5C7.89 5 7.33 5.26 6.95 5.72L5.61 7.32C4.16 9.04 2.24 10 0 10V12C2.77 12 5.19 10.83 7 8.75V11L3.12 12.55C2.45 12.82 2 13.5 2 14.21C2 15.2 2.8 16 3.79 16H6V15.5C6 14.12 7.12 13 8.5 13H11.5C11.78 13 12 13.22 12 13.5C12 13.78 11.78 14 11.5 14H8.5C7.67 14 7 14.67 7 15.5V16H14.21C15.2 16 16 15.2 16 14.21C16 13.5 15.55 12.82 14.88 12.55L11 11V8.75C12.81 10.83 15.23 12 18 12Z"
              fill="black"
            />
          </svg>
          <p>Instructors will see the wireframe, avatars or the biomechanics of the practitioner</p>
        </div>
        <div className="landing_yogifeatures_item">
          <svg
            style={{ width: '10%', margin: 'auto auto 0 auto' }}
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.7 5C18.4 5.4 18 5.7 18 6C18 6.3 18.3 6.6 18.6 7C19.1 7.5 19.6 7.9 19.5 8.4C19.5 8.9 19 9.4 18.5 9.9L14.4 14L13 12.7L17.2 8.5L16.2 7.5L14.8 8.9L11 5.1L15 1.3C15.4 0.9 16 0.9 16.4 1.3L18.7 3.6C19.1 4 19.1 4.7 18.7 5ZM1 15.2L10.6 5.6L14.3 9.4L4.8 19H1V15.2ZM5 0V3H8V5H5V8H3V5H0V3H3V0H5Z"
              fill="black"
            />
          </svg>
          <p>Instructors can customize the Asanas as per practitioner’s physical condition</p>
        </div>
      </div>
    </section>
  );
}

function DownloadApp() {
  let navigate = useNavigate();
  return (
    <section id="landing_download">
      <div className="landing_download_inner">
        <div className="landing_download_left">
          <h1>
            Coming <span style={{ color: '#45a29e' }}>Soon</span>
          </h1>
          <p>
            Experience Yoga like never before with our <br />
            Apps with unlimited premium content and training sessions
          </p>
          <div className="landing_download_button">
            <a
              onClick={() => navigate(process.env.PUBLIC_URL + '/signup')}
              className="landing_iconHover">
              <img
                className="tn-atom__img t-img loaded"
                data-original="https://static.tildacdn.com/tild3262-3663-4634-a133-363763663032/AppStore.svg"
                // imgField="tn_img_1619011369884"
                src="https://static.tildacdn.com/tild3262-3663-4634-a133-363763663032/AppStore.svg"
              />
            </a>
            <a
              onClick={() => navigate(process.env.PUBLIC_URL + '/signup')}
              className="landing_iconHover"
              style={{ marginLeft: '5%' }}>
              <img
                className="tn-atom__img t-img loaded"
                data-original="https://static.tildacdn.com/tild3263-3166-4239-b364-653263626137/GooglePlay.svg"
                // imgField="tn_img_1619011417921"
                src="https://static.tildacdn.com/tild3263-3166-4239-b364-653263626137/GooglePlay.svg"
              />
            </a>
          </div>
        </div>

        <div className="landing_download_right">
          <img src={MobileIMG} className="landing_download_img" alt="Download App" />
        </div>
      </div>
    </section>
  );
}

function Team() {
  return (
    <section id="team" className="team section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Co-founders</h2>
          <p className="subtitle">We have experienced team of experts. </p>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="100"
              style={{ background: 'ghostwhite' }}>
              <div className="pic">
                <img src={team1IMG} className="img-fluid" alt="" />
              </div>
              <div className="member-info">
                <h4>Santosh Yadav</h4>
                <span>Co-Founder, Chief Executive Officer</span>
                <p>Ph.D. CSIR-CEERI</p>
                <div className="social">
                  <a href="">
                    <i className="ri-twitter-fill"></i>
                  </a>
                  <a href="">
                    <i className="ri-facebook-fill"></i>
                  </a>
                  <a href="">
                    <i className="ri-instagram-fill"></i>
                  </a>
                  <a href="">
                    {' '}
                    <i className="ri-linkedin-box-fill"></i>{' '}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 mt-4 mt-lg-0">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="200"
              style={{ background: 'ghostwhite' }}>
              <div className="pic">
                <img src={team2IMG} className="img-fluid" alt="" />
              </div>
              <div className="member-info">
                <h4>Mohit Mathur</h4>
                <span>Co-Founder, Chief Marketing Officer</span>
                <p>Serial Entrepreneur</p>
                <div className="social">
                  <a href="">
                    <i className="ri-twitter-fill"></i>
                  </a>
                  <a href="">
                    <i className="ri-facebook-fill"></i>
                  </a>
                  <a href="">
                    <i className="ri-instagram-fill"></i>
                  </a>
                  <a href="">
                    {' '}
                    <i className="ri-linkedin-box-fill"></i>{' '}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 mt-4">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="300"
              style={{ background: 'ghostwhite' }}>
              <div className="pic">
                <img src={team3IMG} className="img-fluid" alt="" />
              </div>
              <div className="member-info">
                <h4>Ashutoah Bhatia</h4>
                <span>Co-Founder, Chief Technology Officer</span>
                <p>Ph.D. IISc Banglore</p>
                <div className="social">
                  <a href="">
                    <i className="ri-twitter-fill"></i>
                  </a>
                  <a href="">
                    <i className="ri-facebook-fill"></i>
                  </a>
                  <a href="">
                    <i className="ri-instagram-fill"></i>
                  </a>
                  <a href="">
                    {' '}
                    <i className="ri-linkedin-box-fill"></i>{' '}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 mt-4">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="400"
              style={{ background: 'ghostwhite' }}>
              <div className="pic">
                <img src={team4IMG} className="img-fluid" alt="" />
              </div>
              <div className="member-info">
                <h4>Kamlesh Tiwari</h4>
                <span>Co-Founder, Chief Operations Officer</span>
                <p>Ph.D. IIT Kanpur</p>
                <div className="social">
                  <a href="">
                    <i className="ri-twitter-fill"></i>
                  </a>
                  <a href="">
                    <i className="ri-facebook-fill"></i>
                  </a>
                  <a href="">
                    <i className="ri-instagram-fill"></i>
                  </a>
                  <a href="">
                    {' '}
                    <i className="ri-linkedin-box-fill"></i>{' '}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// function Contact() {
//   return (
//     <section id="contact" className="contact">
//       <div className="container" data-aos="fade-up">
//         <div className="section-title">
//           <h2>Contact</h2>
//           <p>You are most welcome to forward your queries at the address below.</p>
//         </div>
//         <div className="row">
//           <div className="col-md-5 d-flex align-items-stretch">
//             <div className="info">
//               <div className="address">
//                 <i className="bi bi-geo-alt"></i>
//                 <h4>Location:</h4>
//                 <p>
//                   Quadrant-2, 10th Floor, Cyber Towers, Madhapur, Hyderabad, Telangana 500081,
//                   India.
//                 </p>
//               </div>
//               <div className="email">
//                 <i className="bi bi-envelope"></i>
//                 <h4>Email:</h4>
//                 <p>info@cognixai.com</p>
//               </div>
//               <div className="phone">
//                 <i className="bi bi-phone"></i>
//                 <h4>Call:</h4>
//                 <p>+91 94157 69688 + 10</p>
//               </div>
//               <iframe
//                 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.1953626625686!2d78.37860001463835!3d17.45036108804092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93ded9f6f0d7%3A0xa3d91e5d00d50b63!2sCyber%20Towers!5e0!3m2!1sen!2sin!4v1655376620211!5m2!1sen!2sin"
//                 frameBorder="0"
//                 style={{ border: 0, width: '100%', height: 'auto' }}
//                 allowfullscreen></iframe>
//             </div>
//           </div>
//           <div className="col-md-7 mt-5 mt-lg-0 d-flex align-items-stretch">
//             <form action="forms/contact.php" method="post" role="form" className="php-email-form">
//               <div className="row">
//                 <div className="form-group col-md-6">
//                   <label htmlFor="name">Your Name</label>
//                   <input type="text" name="name" className="form-control" id="name" required />
//                 </div>
//                 <div className="form-group col-md-6">
//                   <label htmlFor="name">Your Email</label>
//                   <input type="email" className="form-control" name="email" id="email" required />
//                 </div>
//               </div>
//               <div className="form-group">
//                 <label htmlFor="name">Subject</label>
//                 <input type="text" className="form-control" name="subject" id="subject" required />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="name">Message</label>
//                 <textarea className="form-control" name="message" rows="5" required></textarea>
//               </div>
//               <div className="my-3">
//                 <div className="loading">Loading</div>
//                 <div className="error-message"></div>
//                 <div className="sent-message">Your message has been sent. Thank you!</div>
//               </div>
//               <div className="text-center">
//                 <button type="submit">Send Message</button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

function Footer() {
  return (
    <footer id="footer" style={{ background: '#1f2833' }}>
      <div className="footer-top" style={{ paddingBottom: '10px' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-contact">
              <h3>
                <span style={{ color: '#66fcf1' }}>CogniX</span>
              </h3>
              <p>
                {' '}
                Quadrant - 2, 10th Floor, <br /> Cyber Towers, Madhapur, Hyderabad, <br /> Telangana
                500081, India. <br />
                <br />
                <strong>Phone:</strong> +91 94157 69688
                <br />
                <strong>Email:</strong> info@cognixai.com
                <br />
              </p>
            </div>
            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i> <a href="#">Home</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i> <a href="#">About us</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i> <a href="#">Yogi-G</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i> <a href="#">Download App</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i> <a href="#">Privacy policy</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i> <a href="#">AI Yoga App</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i> <a href="#">Activity Monitering</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i> <a href="#">AI for Elderly Care</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i> <a href="#">Sports Analysis</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i> <a href="#">Gait & Physio</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Our Social Networks</h4>
              <p className="footer-socialtext">
                Join us on social networks for more motion ananlysis solutions
              </p>
              <div className="social-links mt-3">
                <a href="https://twitter.com/CognixAI" className="twitter">
                  <i className="bx bxl-twitter"></i>
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100082068323477"
                  className="facebook">
                  <i className="bx bxl-facebook"></i>
                </a>
                {/* <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
                        <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a> */}
                <a href="https://www.linkedin.com/company/cognixai/" className="linkedin">
                  <i className="bx bxl-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container footer-bottom clearfix"
        style={{ paddingLeft: '34vw', paddingTop: '0px' }}>
        <div className="copyright">
          {' '}
          &copy; Copyright{' '}
          <strong>
            <span>CogniX</span>
          </strong>
          . All Rights Reserved
        </div>
      </div>
    </footer>
  );
}

export default Landing;
