import DashboardIcon from './nav-icons/DashboardIcon';
// import AnalyticsIcon from './nav-icons/AnalyticsIcon';
import YogaPosesIcon from './nav-icons/YogaPosesIcon';
import PrescriptionIcon from './nav-icons/PrescriptionIcon';
// import UsersIcon from './nav-icons/UsersIcon';
import AccountIcon from './nav-icons/AccountIcon';
import LogoutIcon from './nav-icons/LogoutIcon';

const navList = [
  {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    path: '/instructor'
  },
  // {
  //   name: 'Analytics',
  //   icon: <AnalyticsIcon />,
  //   path: '/instructor/analytics'
  // },
  {
    name: 'My Yoga Poses',
    icon: <YogaPosesIcon />,
    path: '/instructor/yoga-poses'
  },
  {
    name: 'My Prescription',
    icon: <PrescriptionIcon />,
    path: '/instructor/prescription'
  }
  // {
  //   name: 'Users',
  //   icon: <UsersIcon />,
  //   path: '/instructor/users'
  // }
];

const navBottomList = [
  {
    name: 'Account',
    icon: <AccountIcon />,
    path: '/instructor/account'
  },
  {
    name: 'Logout',
    icon: <LogoutIcon />,
    path: '/instructor/login'
  }
];

export { navBottomList };
export default navList;
