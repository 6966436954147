import React from 'react';
import axios from '../../axios';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';

/* MUI */
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';

/* CSS */
import styles from '../../css/Instructor/ForgotPassword.module.css';

/* ReCAPTCHA */
import ReCAPTCHA from 'react-google-recaptcha';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = React.useState('');

  const handleSubmit = () => {
    if (!validator.isEmail(email)) {
      enqueueSnackbar('Please enter correct Email ID!', { variant: 'error' });
      return;
    }
    axios
      .post(`/users/verify_existence/`, {
        email: email
      })
      .then((response) => {
        console.log(response);
        response.data.exists
          ? enqueueSnackbar('Password Reset Mail Sent!', { variant: 'success' })
          : enqueueSnackbar('User does not exist!', { variant: 'error' });
        setEmail('');
      })
      .catch((error) => {
        console.log(error);
        if (error.request.status >= 500)
          enqueueSnackbar(error.request.statusText, { variant: 'error' });
        else enqueueSnackbar('Error! ' + error.response.data.detail, { variant: 'error' });
      });
  };

  function onChange(value) {
    console.log('Captcha value:', value);
  }

  return (
    <div className={styles.outerdiv}>
      <div className={styles.head}>
        <div className={styles.head1}>Welcome Back</div>
        <div className={styles.head2}>Forgot Password?</div>
      </div>
      <h5>Enter your registered email ID to recieve a password reset link on your mail</h5>
      <div className={styles.textField}>
        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          sx={{ width: '100%', mt: 3, mb: 3 }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <ReCAPTCHA sitekey="6LdaWEIhAAAAACy5qxjMUwiRJdOoMkWsP7X2gA6b" onChange={onChange} />,
        <div className={styles.submitButton} onClick={() => handleSubmit()}>
          Submit
        </div>
      </div>
      <div className={styles.signupOuter}>
        Already a member?&nbsp;&nbsp;
        <span className={styles.signUpLink} onClick={() => navigate('/instructor/login')}>
          <u>Log In</u>
        </span>
      </div>
      <div className={styles.signupOuter}>
        New to the family?&nbsp;&nbsp;
        <span className={styles.signUpLink} onClick={() => navigate('/instructor/signup')}>
          <u>Sign Up</u>
        </span>
      </div>
    </div>
  );
};

export default ForgotPassword;
