import React from 'react';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

import styles from './BlogTemplate.module.css';

const BlogTemplate = (props) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : props.background_color,
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  }));
  const [liked, setLiked] = React.useState(false);
  const [likes, setLikes] = React.useState(Math.floor(Math.random() * 100));
  React.useEffect(() => {
    setLikes(Math.floor(Math.random() * 100));
    setLiked(false);
  }, [props]);

  const handleLikes = () => {
    setLiked(!liked);
    if (liked) setLikes(likes - 1);
    else setLikes(likes + 1);
  };

  return (
    <Box>
      <Item>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            m: '10px auto',
            width: '90%'
          }}>
          <Typography sx={{ width: '10%' }}></Typography>
          <Typography variant="h4" sx={{ width: '50%' }} align="center">
            {props.title}
          </Typography>
          <Typography sx={{ width: '10%', display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={handleLikes}>
              {!liked ? <FavoriteBorderIcon /> : <FavoriteIcon style={{ color: 'red' }} />}
            </IconButton>
            <Typography>{likes}</Typography>
          </Typography>
        </Box>

        <Box sx={{ m: '10px auto', p: 2 }}>
          <Typography variant="h6" align="center">
            {props.category} | {props.date_to_live ? props.date_to_live.substring(0, 11) : <></>}
          </Typography>
        </Box>

        <Box sx={{ m: '20px auto' }}>
          <Item sx={{ minHeight: 500 }}>
            {props.image ? (
              <div className={styles.imageContainer}>
                <img
                  src={!props.fromApi ? URL.createObjectURL(props.image) : props.image}
                  alt=""
                  className={styles.image}
                />
              </div>
            ) : (
              <></>
            )}
            {props.content &&
              props.content.split('\n').map((item, idx) => (
                <Typography key={idx} sx={{ m: 2, wordWrap: 'break-word' }} variant="h6">
                  {item}
                </Typography>
              ))}
          </Item>
        </Box>
      </Item>
    </Box>
  );
};
export default BlogTemplate;
