import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import Sidebar from './../../Components/Sidebar/Sidebar';
/* CSS */
import styles from '../../css/Instructor/Instructor.module.css';

/* List */
import navList, { navBottomList } from './Navlist';

const Instructor = () => {
  //   console.log('Portal Outer Check ', navList);
  // const [isAuth, setIsAuth] = React.useState(localStorage.getItem('access-token'));
  const isAuth = localStorage.getItem('access-token');

  return isAuth ? (
    <div className={styles.instructorOuter}>
      <Sidebar navList={navList} navBottomList={navBottomList} />
      <div style={{ width: '100%', margin: '10px 0', padding: '0 2.5%', backgroundColor:'#FAF9F5' }}>
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to={'/instructor/login'} />
  );
};

export default Instructor;
