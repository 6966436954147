import React from 'react';
import Chart from '../../Components/chart/Chart';
import styles from '../../css/Instructor/Single.module.css';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Stack, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
//import { Link } from 'react-router-dom';
import Sidebar from '../../Components/Sidebar/Sidebar';
import navList, { navBottomList } from './Navlist';
const Single = () => {
  /*     export default function BasicSelect() {
        const [age, setAge] = React.useState('');
      
        const handleChange = (event) => {
          setAge(event.target.value);
        }; */
  const [prescription, setPrescription] = React.useState('');

  const handleChange = (event) => {
    setPrescription(event.target.value);
  };
  const navigate = useNavigate();
  const navigateToChats = () => {
    navigate('/instructor/chats');
  };
  return (
    <div className={styles.single}>
      <div className={styles.top}>
        <div className={styles.left}>
          <Sidebar navList={navList} navBottomList={navBottomList} />

          <div className={styles.item}>
            <img
              src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt=""
              className={styles.itemImg}
            />
            <div className={styles.details}>
              <h1 className="itemTitle">Jenny Doe</h1>
              <div className={styles.detailItem}>
                <span className={styles.itemKey}>Email:</span>
                <span className={styles.itemValue}>jennydoe@gmail.com</span>
              </div>
              <div className={styles.detailItem}>
                <span className={styles.itemKey}>Age:</span>
                <span className={styles.itemValue}>23</span>
              </div>
              <div className={styles.detailItem}>
                <span className={styles.itemKey}>Weight:</span>
                <span className={styles.itemValue}>58kg</span>
              </div>
              <div className={styles.detailItem}>
                <span className={styles.itemKey}>Gender:</span>
                <span className={styles.itemValue}>Female</span>
              </div>
              <div className={styles.detailItem}>
                <Box sx={{ minWidth: 120, marginTop: 5 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Prescription</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={prescription}
                      label="Prescription"
                      onChange={handleChange}>
                      <MenuItem value={1}>BackPain</MenuItem>
                      <MenuItem value={2}>NeckPain</MenuItem>
                      <MenuItem value={3}>Diabetes</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <Chart aspect={3 / 1} title="Activity for past 5 days" />
        </div>
      </div>
      <div className={styles.bottom}>
        <Stack direction="row" spacing={70}>
          <Button variant="contained" color="success">
            Accept
          </Button>
          <Button variant="outlined" color="error">
            Reject
          </Button>

          <Button color="secondary" onClick={navigateToChats}>
            Save
          </Button>
        </Stack>
      </div>
      <div className={styles.chat}>
        <div className={styles.chat_top}>
          <div className={styles.chat_left}>fwsfsf</div>
        </div>
      </div>
    </div>
  );
};

export default Single;
