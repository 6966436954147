import TopBar from '../../Components/TopBar/TopBar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip , PieChart , Pie} from 'recharts';
//dummy data for the area chart
const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
//dummy data for pie chart
const data01 = [
  {
    "name": "Group A",
    "value": 400
  },
  {
    "name": "Group B",
    "value": 300
  },
  {
    "name": "Group C",
    "value": 300
  },
  {
    "name": "Group D",
    "value": 200
  },
  {
    "name": "Group E",
    "value": 278
  },
  {
    "name": "Group F",
    "value": 189
  }
];

const Analytics = () => {
  return (
    <div>
      <TopBar title={'Analytics'} />
        <Stack direction ="row" spacing = {2}>
      <Card sx={{ maxWidth: 320 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
         Stats-1
        </Typography>
        <Typography variant="body2" color="text.secondary">
          BackPain is one of the most common reasons people seek medical help or miss work. Back pain is a leading cause of disability worldwide.
        </Typography>
      </CardContent>
    </Card>
    <Card sx={{ maxWidth: 320 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        Stats-2
        </Typography>
        <Typography variant="body2" color="text.secondary">
         NeckPain is one of the most common reasons people seek medical help or miss work. Back pain is a leading cause of disability worldwide
        </Typography>
      </CardContent>
    </Card>
    <Card sx={{ maxWidth: 320 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Stats-3
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Obesity is one of the most common reasons people seek medical help or miss work. Back pain is a leading cause of disability worldwide
        </Typography>
      </CardContent>
    </Card>
    <Card sx={{ maxWidth: 320 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Stats-4
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Asanas is a body posture, originally and still a general term for a sitting meditation pose, and later extended in hatha yoga and modern yoga as exercise, to any type of position, adding reclining, standing and balancing poses.
        </Typography>
      </CardContent>
    </Card>
    </Stack>
    <Card sx = {{height : 70 + "vh"}}>
      <CardContent>
        <div className='title'>Progress Chart</div>
        <Stack direction="row" spacing={2}>
      <AreaChart width={830} height={450} data={data}
  margin={{ top: 30, right: 30, left: 0, bottom: 0 }}>
  <defs>
  
    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
    </linearGradient>
  </defs>
  <XAxis dataKey="name" />
  <YAxis />
  <CartesianGrid strokeDasharray="3 3" />
  <Tooltip />
  <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
</AreaChart>
<PieChart width={530} height={550} >
  <Pie data={data01} dataKey="value" nameKey="name" cx="50%" cy="40%" outerRadius={200} fill="#8884d8" />
</PieChart>
</Stack>
      </CardContent>
    </Card>
    </div>
  );
};
export default Analytics;