import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/* CSS */
// import styles from './NavbarV.modules.css';

/* Material UI */
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import CognixLogo from './nav-icons/CognixLogo';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// /* List */
// import navList from './NavList'
// import {navContactList} from './NavList'

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);

const Sidebar = (props) => {
  let navigate = useNavigate();
  const id = useLocation();
  // console.log(id.pathname)
  // console.log(props);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <AppBar
        position="fixed"
        open={open}
        sx={{ backgroundColor: '#fff', boxShadow: 'none', ...(open && { display: 'none' }) }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              color: '#000',
              marginRight: 5
              // ...(open && { display: 'none' })
            }}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} sx={{ borderRight: 5 }}>
        <DrawerHeader sx={{ justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CognixLogo />
            <h5
              style={{
                color: 'black',
                fontSize: '20px',
                fontFamily: 'system-ui',
                margin: '0',
                marginLeft: '5px'
              }}>
              Yogi-G
            </h5>
          </div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        {open ? <></> : <Divider />}

        <List sx={{ mt: 5 }}>
          {props.navList.map((val, index) => (
            <ListItem key={index} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  mx: open ? 1.5 : 1,
                  backgroundColor: val.path === id.pathname ? '#C8FACD90' : '#fff',
                  borderRadius: '1rem'
                }}
                onClick={() => val.path != id.pathname && navigate(val.path)}>
                <ListItemIcon
                  sx={{
                    color: 'rgb(33, 43, 54)',
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center'
                  }}>
                  {val.icon}
                </ListItemIcon>
                <ListItemText
                  primary={val.name}
                  sx={{
                    color: '#425466',
                    opacity: open ? 1 : 0
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider sx={{ mt: 3, mb: 3 }} />
        {/* <List>
          {props.navContactList.map((val, index) => (
            <ListItem key={index} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5
                }}>
                <ListItemIcon
                  sx={{
                    color: 'rgb(33, 43, 54)',
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center'
                  }}>
                  {val.icon}
                </ListItemIcon>
                <ListItemText
                  primary={val.name}
                  sx={{ color: 'rgb(33, 43, 54)', opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
        <List>
          {props.navBottomList.map((val, index) => (
            <ListItem key={index} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  mx: open ? 1.5 : 1,
                  backgroundColor: val.path === id.pathname ? '#C8FACD90' : '#fff',
                  borderRadius: '1rem'
                }}
                onClick={() => {
                  val.name == 'Logout' ? localStorage.removeItem('access-token') : 1;
                  val.path != id.pathname && navigate(val.path);
                }}>
                <ListItemIcon
                  sx={{
                    color: 'rgb(33, 43, 54)',
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center'
                  }}>
                  {val.icon}
                </ListItemIcon>
                <ListItemText
                  primary={val.name}
                  sx={{
                    color: '#425466',
                    opacity: open ? 1 : 0
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default Sidebar;
