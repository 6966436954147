import { createBrowserRouter, Navigate } from 'react-router-dom';
import Landing from './Pages/Landing/Landing';
import InstructorLogin from './Pages/Instructor/Login';
import Instructor from './Pages/Instructor/Instructor';
import Analytics from './Pages/Instructor/Analytics';
import Dashboard from './Pages/Instructor/Dashboard';
import Users from './Pages/Instructor/Users';
import YogaPoses from './Pages/Instructor/YogaPoses';
import Prescription from './Pages/Instructor/Prescription';
import InstructorSignup from './Pages/Instructor/Signup';
import CreatePrescription from './Pages/Instructor/CreatePrescription';
import EditPrescription from './Pages/Instructor/EditPrescription';
import BlogEditor from './Components/NewBlogs/BlogEditor/BlogEditor';
import BlogTemplate from './Components/NewBlogs/BlogTemplate/BlogTemplate';
import BlogView from './Components/NewBlogs/BlogView/BlogView';
import CreateYogaPose from './Pages/Instructor/CreateYogaPose';
import EditYogaPose from './Pages/Instructor/EditYogaPose';
import Account from './Pages/Instructor/Account';
import ForgotPassword from './Pages/Instructor/ForgotPassword';
import ResetPassword from './Pages/Instructor/ResetPassword';
import Single from './Pages/Instructor/Single';
// import Home from './Pages/Instructor/Chats/Home';
import Chat from './Pages/Instructor/Chat';
const router = createBrowserRouter([
  {
    path: '/',
    element: <Landing />
  },
  {
    path: '/editor',
    element: <BlogEditor />
  },
  {
    path: '/instructor/single',
    element: <Single />
  },
  {
    path: '/instructor/chats',
    element: <Chat />
  },
  {
    path: '/blog/template',
    element: <BlogTemplate />
  },
  {
    path: '/blog/view/:id',
    element: <BlogView />
  },
  {
    path: '/instructor',
    element: <Instructor />,
    children: [
      {
        index: true,
        element: <Dashboard />
      },
      {
        path: 'analytics',
        element: <Analytics />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'yoga-poses',
        children: [
          {
            index: true,
            element: <YogaPoses />
          },
          {
            path: 'new',
            element: <CreateYogaPose />
          },
          {
            path: 'edit/:id',
            element: <EditYogaPose />
          }
        ]
      },
      {
        path: 'prescription',
        children: [
          {
            index: true,
            element: <Prescription />
          },
          {
            path: 'new',
            element: <CreatePrescription />
          },
          {
            path: 'edit/:id',
            element: <EditPrescription />
          }
        ]
      },
      {
        path: 'users',
        element: <Users />
      }
    ]
  },
  {
    path: '/instructor/login',
    element: <InstructorLogin />
  },
  {
    path: '/instructor/signup',
    element: <InstructorSignup />
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />
  },
  {
    path: '/reset-password/:id',
    element: <ResetPassword />
  },
  {
    path: '*',
    element: <Navigate to="/" replace />
  }
]);

export default router;
