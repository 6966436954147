import * as React from 'react';
import TopBar from '../../Components/TopBar/TopBar';

/* CSS */
import styles from '../../css/Instructor/YogaPoses.module.css';

/* MUI */
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { useNavigate } from 'react-router-dom';
//import { useSnackbar } from 'notistack';

import axios from '../../axios';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid rgba(0,0,0,0.5)',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'rgba(0,0,0,0.8)'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch'
    }
  }
}));

const getCurrentUser = async () => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('access-token')}` }
    };

    const response = await axios.get('/users/profile/', config);
    const userId = response.data.id;
    return userId;
  } catch (error) {
    console.log('Error fetching current user:', error);
    return null; // Return null or handle the error appropriately
  }
};

const YogaPoses = () => {
  const navigate = useNavigate();
  //const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = React.useState('');
  const [yogaposes, setYogaposes] = React.useState([]);
  const handleChange = (val) => {
    setName(val);
    console.log(name);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = await getCurrentUser();
        if (userId) {
          const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('access-token')}` }
          };
  
          const response = await axios.get('/content/videos/', config);
          // Filter the poses based on the current user's ID
          const filteredPoses = response.data.filter((pose) => pose.instructor.id === userId);
          setYogaposes(filteredPoses);
        } else {
          // Handle case where user ID is not available
          console.log('User ID not available');
        }
      } catch (error) {
        console.log('Error fetching yoga poses:', error);
        // Handle error fetching yoga poses
      }
    };
  
    fetchData();
  }, []);
  

  const TableRowComponent = (props) => {
    let thumbnail_url = `https://cognixai.com/back${props.thumbnail}`;
    // let file_url = `https://cognixai.com/back${props.file}`;
    return (
      <div
        className={styles.tableRow}
        onClick={() => navigate(`/instructor/yoga-poses/edit/${props.id}`)}>
        <div>{props.id}</div>
        <div style={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}>
          <div
            style={{
              width: '45%',
              height: '100%',
              backgroundColor: 'pink',
              marginRight: '5%'
            }}>
            {/* <img src={props.img} alt="" /> */}
            <img src={thumbnail_url} alt="" style={{ height: '100%', width: '100%' }} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '50%',
              height: '100%',
              justifyContent: 'space-around',
              textAlign: 'left'
            }}>
            <div style={{ width: '100%', textAlign: 'left' }}>
              <b>{props.name}</b>
            </div>
            <div style={{ width: '100%', textAlign: 'left' }}>{props.short_desc}</div>
          </div>
        </div>
        <div>
          <div style={{ width: '100%' }}>15 Videos</div>
          <div style={{ width: '100%' }}>{props.duration} Minutes</div>
          <div style={{ width: '100%' }}>15 Users</div>
        </div>
        <div>View Analytics</div>
      </div>
    );
  };

  return (
    <div>
      <TopBar title={'My Yoga Poses'} />
      <div className={styles.topRow}>
        <Button variant="contained" onClick={() => navigate('/instructor/yoga-poses/new')}>
          Add New Yoga Pose
        </Button>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            onChange={(e) => handleChange(e.target.value)}
          />
        </Search>
      </div>
      <div id={styles.table}>
        <div className={styles.tableHead}>
          <div></div>
          <div>Pose</div>
          <div>Details</div>
          <div>Analytics</div>
        </div>

        {yogaposes.map((item, idx) => (
          <TableRowComponent
            key={idx}
            id={idx + 1}
            desc={item.category.description}
            short_desc={item.short_desc}
            long_desc={item.long_desc}
            name={item.name}
            thumbnail={item.thumbnail}
            file={item.file}
            duration={item.duration}
          />
        ))}
      </div>
    </div>
  );
};
export default YogaPoses;
