import React from 'react';
import axios from '../../axios';
import { useNavigate, useParams } from 'react-router-dom';

/* MUI */
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';

/* CSS */
import styles from '../../css/Instructor/ResetPassword.module.css';

const ResetPassword = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');

  const navigate = useNavigate();
  const params = useParams();
  console.log(params.id);

  const handleSubmit = () => {
    password === password2
      ? axios
          .post(`/users/reset_password/`, {
            password: password,
            password2: password2,
            token: params.id
          })
          .then((response) => {
            console.log(response);
            enqueueSnackbar('Password Reset Successfully!', { variant: 'success' });
            setTimeout(() => {
              navigate('/');
            }, 200);
          })
          .catch((error) => {
            console.log(error);
            if (error.request.status >= 500)
              enqueueSnackbar(error.request.statusText, { variant: 'error' });
            else enqueueSnackbar('Error! ' + error.response.data.detail, { variant: 'error' });
          })
      : enqueueSnackbar('Passwords DO NOT MATCH!', { variant: 'error' });
  };

  return (
    <div className={styles.outerdiv}>
      <div className={styles.head}>
        <div className={styles.head2}>Reset Password</div>
      </div>
      <div className={styles.textField}>
        <TextField
          id="outlined-basic"
          label="Enter Password"
          variant="outlined"
          type="password"
          sx={{ width: '100%', mt: 3, mb: 3 }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          id="outlined-basic"
          label="Re-Enter Password"
          variant="outlined"
          type="password"
          sx={{ width: '100%', mt: 3, mb: 3 }}
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
        />
        <div className={styles.submitButton} onClick={() => handleSubmit()}>
          Submit
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
