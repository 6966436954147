import React from 'react';
import TopBar from '../../Components/TopBar/TopBar';

/* MUI */
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

/* CSS */
import styles from '../../css/Instructor/CreatePrescription.module.css';

// Axios
import axios from '../../axios';

const EditPrescription = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [videos, setVideos] = React.useState([]);

  React.useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem(`access-token`)}` }
    };

    axios
      .get('/content/videos/', config)
      .then((res) => {
        console.log(res);
        setVideos(res.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.request.status == 401) {
          enqueueSnackbar('Session Expired, Login Again', { variant: 'error' });
          localStorage.removeItem('access-token');
          navigate(`/instructor/login`);
        }
      });
  }, []);

  React.useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem(`access-token`)}` }
    };
    axios
      .get(`/content/prescriptions/${params.id}/`, config)
      .then((res) => {
        console.log(res.data);
        setNameDesc(res.data.name);
        setLongDesc(res.data.long_desc);
        setShortDesc(res.data.short_desc);

        const stepsTemp = [];
        res.data.steps.forEach((val) => {
          let temp = [];
          val.videos.forEach((valu) => {
            if (videos[valu.video - 1]) temp.push(videos[valu.video - 1]);
          });
          stepsTemp.push({ selected: val.to_choose, value: temp });
        });
        setTableRowArray(stepsTemp);
      })
      .catch((error) => {
        console.log(error);
        if (error.request.status == 401) {
          enqueueSnackbar('Session Expired, Login Again', { variant: 'error' });
          localStorage.removeItem('access-token');
          navigate(`/instructor/login`);
        }
      });
  }, [videos]);

  const TableRow = (props) => {
    // const [selectedCount, setSelectedCount] = React.useState(0);
    const handleChangeSelected = (val) => {
      // console.log('test', val);
      setTableRowArray((items) => [
        ...items.slice(0, props.index),
        { value: val, selected: items[props.index].selected },
        ...items.slice(props.index + 1)
      ]);
      console.log(tableRowArray);
    };

    const handleChangeCount = (val) => {
      val = parseInt(val);
      if (val < 0 || val > tableRowArray[props.index].value.length) return;
      setTableRowArray((items) => [
        ...items.slice(0, props.index),
        { value: items[props.index].value, selected: val },
        ...items.slice(props.index + 1)
      ]);
      console.log({ value: val }, tableRowArray);
    };

    return (
      <div className={styles.tableInner}>
        <p>{props.index + 1}.</p>
        <Autocomplete
          multiple
          id="tags-outlined"
          //   size="small"
          options={videos}
          getOptionLabel={(option) => option.name}
          // defaultValue={[top100Films[5]]}
          value={tableRowArray[props.index].value}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label="Selected Yoga Poses"
              placeholder="Click to Select Yoga Pose"
            />
          )}
          onChange={(event, newValue) => handleChangeSelected(newValue)}
          sx={{ mx: 6 }}
          fullWidth
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            type="number"
            // size="small"
            inputProps={{ min: 0, max: tableRowArray[props.index].value.length, step: 1 }}
            value={tableRowArray[props.index].selected}
            sx={{ width: '80px', mx: 1 }}
            onChange={(e) => handleChangeCount(e.target.value)}
          />
          <p style={{ fontSize: '20px', fontWeight: '600' }}>
            /{tableRowArray[props.index].value.length}
          </p>
        </div>
      </div>
    );
  };

  const [tableRowArray, setTableRowArray] = React.useState([]);
  let tableRowList = [];
  tableRowArray.forEach((value, index) => {
    tableRowList.push(<TableRow key={index} index={index} value={value} />);
  });

  const handleAddRow = () => {
    setTableRowArray((prevItems) => [...prevItems, { value: [], selected: 0 }]);
  };

  //   React.useEffect(() => {
  //     // console.log(tableRowArray);
  //   }, [tableRowArray]);

  const [longdesc, setLongDesc] = React.useState('');
  const [shortdesc, setShortDesc] = React.useState('');
  const [namedesc, setNameDesc] = React.useState('');
  function handleSave() {
    console.log(tableRowArray, namedesc, shortdesc, longdesc);

    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem(`access-token`)}` }
    };
    const stepsTemp = [];
    tableRowArray.forEach((val, ind) => {
      let temp = [];
      val.value.forEach((valu) => {
        temp.push({ video: valu.id });
      });
      stepsTemp.push({ sno: ind + 1, to_choose: val.selected, videos: temp });
    });
    console.log({ name: namedesc, short_desc: shortdesc, long_desc: longdesc, steps: stepsTemp });
    axios
      .patch(
        `/content/prescriptions/${params.id}/`,
        { name: namedesc, short_desc: shortdesc, long_desc: longdesc, steps: stepsTemp },
        config
      )
      .then((res) => {
        console.log(res);
        enqueueSnackbar('Saved Successfully', { variant: 'success' });
      })
      .catch((error) => {
        console.log(error);
        if (error.request.status == 401) {
          enqueueSnackbar('Session Expired, Login Again', { variant: 'error' });
          localStorage.removeItem('access-token');
          navigate(`/instructor/login`);
        }
      });
  }

  return (
    <div>
      <TopBar title={'Edit Prescription'} />
      <TextField
        id="input-with-icon-textfield"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <p>Name</p>
            </InputAdornment>
          )
        }}
        variant="standard"
        value={namedesc}
        onChange={(e) => setNameDesc(e.target.value)}
        sx={{ width: '40%' }}
      />
      <div className={styles.topOuter}>
        <div className={styles.topLeft}>
          <TextField
            id="filled-textarea"
            label="Enter Description"
            placeholder="Write something for user's reference"
            multiline
            value={longdesc}
            onChange={(e) => setLongDesc(e.target.value)}
            rows={4}
            variant="filled"
            sx={{ width: '90%', mb: 1 }}
          />
          <TextField
            id="filled-textarea"
            label="Enter Notes"
            placeholder="Write something for your reference"
            multiline
            value={shortdesc}
            onChange={(e) => setShortDesc(e.target.value)}
            rows={4}
            variant="filled"
            sx={{ width: '90%', mt: 1 }}
          />
        </div>
        <div className={styles.topRight}></div>
      </div>
      <div className={styles.tableOuter}>
        <h3>Enter The Prescribed Poses</h3>
        {tableRowList}
        <Button
          variant="contained"
          endIcon={<AddIcon />}
          sx={{ borderRadius: '1rem', mt: 3 }}
          onClick={() => handleAddRow()}>
          Add More Pose Set
        </Button>
      </div>
      <div className={styles.buttonOuter}>
        <Button variant="contained" sx={{ width: '20vw' }}>
          Preview Video
        </Button>
        <Button variant="contained" sx={{ width: '20vw' }} onClick={() => handleSave()}>
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default EditPrescription;
