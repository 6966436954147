const AccountIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <mask
        id="mask0_951_5739"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="17">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6667 4.5C10.6667 5.97276 9.47276 7.16667 8 7.16667C6.52724 7.16667 5.33333 5.97276 5.33333 4.5C5.33333 3.02724 6.52724 1.83333 8 1.83333C9.47276 1.83333 10.6667 3.02724 10.6667 4.5ZM8 8.5C10.2091 8.5 12 6.70914 12 4.5C12 2.29086 10.2091 0.5 8 0.5C5.79086 0.5 4 2.29086 4 4.5C4 6.70914 5.79086 8.5 8 8.5ZM16 15.1667C16 16.5 14.6667 16.5 14.6667 16.5H1.33333C1.33333 16.5 0 16.5 0 15.1667C0 13.8333 1.33333 9.83333 8 9.83333C14.6667 9.83333 16 13.8333 16 15.1667ZM14.6666 15.162C14.6647 14.833 14.4616 13.8473 13.5572 12.9428C12.6875 12.0731 11.0521 11.1667 7.99999 11.1667C4.94784 11.1667 3.31247 12.0731 2.4428 12.9428C1.53834 13.8473 1.33523 14.833 1.33333 15.162H14.6666Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_951_5739)">
        <rect y="0.5" width="16" height="16" fill="#A0AEC0" />
      </g>
    </svg>
  );
};
export default AccountIcon;
