import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import TopBar from '../../Components/TopBar/TopBar';

/* MUI */
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';

/* CSS */
import styles from '../../css/Instructor/Account.module.css';
import { Button } from '@mui/material';

/* Profile Image */
import { Avatar, IconButton } from '@mui/material';

const demo_header_img = process.env.PUBLIC_URL + '/assets/images/user_headerimg.png';

const Account = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(`access-token`)}` }
  };
  const [details, setDetails] = React.useState({});

  const [first_name, setFirst_Name] = React.useState('');
  const [last_name, setLast_Name] = React.useState('');
  const [age, setAge] = React.useState('');
  const [address1, setAddress1] = React.useState('');
  const [address2, setAddress2] = React.useState('');
  const [sex, setSex] = React.useState('');
  const [mobile, setMobile] = React.useState('');
  const [city, setCity] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [desc, setDesc] = React.useState('');
  const [bio, setBio] = React.useState('');
  const [bank_name, setBank_Name] = React.useState('');
  const [bank_address1, setBank_Address1] = React.useState('');
  const [bank_address2, setBank_Address2] = React.useState('');
  const [bank_account_holder_name, setBank_Account_Holder_Name] = React.useState('');
  const [bank_account_number, setBank_Account_Nuber] = React.useState('');
  const [bank_city, setBank_City] = React.useState('');
  const [bank_country, setBank_Country] = React.useState('');
  const [bank_ifsc_code, setIFSCCode] = React.useState('');
  const [profile_pic, setProfile_Pic] = React.useState([]);
  const [newImageProfileUrl, setNewImageProfileUrl] = React.useState('');

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setProfile_Pic(e.target.files[0]);
      const newImg = URL.createObjectURL(e.target.files[0]);
      setNewImageProfileUrl(newImg);
    }
  };

  function handleSave() {
    console.log(
      first_name,
      last_name,
      age,
      address1,
      address2,
      sex,
      mobile,
      city,
      country,
      desc,
      bio,
      bank_name,
      bank_address1,
      bank_address2,
      bank_account_holder_name,
      bank_account_number,
      bank_city,
      bank_country,
      bank_ifsc_code,
      profile_pic
    );
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem(`access-token`)}` }
    };

    axios
      .patch(
        'https://cognixai.com/back/users/profile/update/',
        {
          first_name: first_name,
          last_name: last_name,
          age: age,
          address1: address1,
          address2: address2,
          sex: sex,
          mobile: mobile,
          city: city,
          country: country,
          desc: desc,
          bio: bio,
          bank_name: bank_name,
          bank_address1: bank_address1,
          bank_address2: bank_address2,
          bank_account_holder_name: bank_account_holder_name,
          bank_account_number: bank_account_number,
          bank_city: bank_city,
          bank_country: bank_country,
          bank_ifsc_code: bank_ifsc_code,
          profile_pic: profile_pic
        },
        config
      )
      .then((res) => {
        console.log(res);
        enqueueSnackbar('Saved Sucessfully!', { variant: 'success' });
        fetchInstructorDetails();
      })
      .catch((error) => {
        console.log(error);
        if (error.request.status == 401) {
          enqueueSnackbar('Session Expired, Login Again', { variant: 'error' });
          localStorage.removeItem('access-token');
          navigate(`/instructor/login`);
        }
      });
  }

  function fetchInstructorDetails() {
    axios
      .get(`https://cognixai.com/back/users/profile/`, config)
      .then((response) => {
        console.log(response);
        setDetails(response.data);
        setFirst_Name(response.data.first_name);
        setLast_Name(response.data.last_name);
        setAge(response.data.age);
        setAddress1(response.data.address1);
        setAddress2(response.data.address2);
        setSex(response.data.sex);
        setMobile(response.data.mobile);
        setCity(response.data.city);
        setCountry(response.data.country);
        setDesc(response.data.desc);
        setBio(response.data.bio);
        setBank_Name(response.data.bank_name);
        setBank_Address1(response.data.bank_address1);
        setBank_Address2(response.data.bank_address2);
        setBank_Account_Holder_Name(response.data.bank_account_holder_name);
        setBank_Account_Nuber(response.data.bank_account_number);
        setBank_City(response.data.bank_city);
        setBank_Country(response.data.bank_country);
        setIFSCCode(response.data.bank_ifsc_code);
        setProfile_Pic(response.data.profile_pic);
      })
      .catch((error) => {
        console.log(error);
        if (error.request.status == 401) {
          enqueueSnackbar('Session Expired, Login Again', { variant: 'error' });
          localStorage.removeItem('access-token');
          navigate(`/instructor/login`);
        }
      });
  }
  React.useEffect(() => {
    fetchInstructorDetails();
  }, []);

  return (
    <div>
      <TopBar title={'Profile'} />
      <div className={styles.topRow}>
        <div
          className={styles.topRowTop}
          style={{
            backgroundImage: `url(${demo_header_img})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          }}></div>
        <div className={styles.topRowBottom}>
          <div style={{ width: '90%', margin: 'auto' }}>
            <h1>
              {details.first_name} {details.last_name}
            </h1>
            <h2>@{details.email}</h2>
            <h2>
              {details.city}, {details.country}
            </h2>
          </div>
        </div>
        <div className={styles.circlePIC}>
          <label htmlFor="contained-button-file">
            <IconButton>
              <Avatar
                src={newImageProfileUrl}
                style={{
                  width: '14vw',
                  height: '14vw'
                }}
              />
            </IconButton>
          </label>
          <div className={styles.overlay}>
            <input
              accept="image/*"
              className={styles.profileInput}
              id="contained-button-file"
              multiple
              type="file"
              onChange={onImageChange}
            />
          </div>
        </div>
      </div>
      <div className={styles.basicInfoOuter}>
        <h1>Basic Information</h1>
        <div
          style={{
            width: '100%',
            backgroundColor: '#DDDDDD',
            paddingTop: '10px',
            paddingBottom: '10px'
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '30px',
              marginBottom: '30px'
            }}>
            <TextField
              required
              id="outlined-required"
              value={first_name}
              onChange={(e) => setFirst_Name(e.target.value)}
              label="First_Name"
              sx={{ width: '40%' }}
            />
            <TextField
              required
              id="outlined-required"
              value={last_name}
              onChange={(e) => setLast_Name(e.target.value)}
              label="Last_Name"
              sx={{ width: '40%' }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '30px',
              marginBottom: '30px'
            }}>
            <TextField
              required
              id="outlined-required"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              label="Age"
              sx={{ width: '40%' }}
            />
            <TextField
              required
              id="outlined-required"
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
              label="Address Location 1"
              sx={{ width: '40%' }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '30px',
              marginBottom: '30px'
            }}>
            <TextField
              required
              id="outlined-required"
              label="Sex"
              value={sex}
              onChange={(e) => setSex(e.target.value)}
              sx={{ width: '40%' }}
            />
            <TextField
              required
              id="outlined-required"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              label="Address Location 2"
              sx={{ width: '40%' }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '30px',
              marginBottom: '30px'
            }}>
            <TextField
              required
              id="outlined-required"
              onChange={(e) => setMobile(e.target.value)}
              value={mobile}
              label="Phone Number"
              sx={{ width: '40%' }}
            />
            <TextField
              required
              id="outlined-required"
              label="City"
              onChange={(e) => setCity(e.target.value)}
              value={city}
              sx={{ width: '40%' }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '30px',
              marginBottom: '30px'
            }}>
            <TextField
              disabled
              id="outlined-required"
              // label="Email Address"
              value={details.email}
              sx={{ width: '40%' }}
            />
            <TextField
              required
              id="outlined-required"
              label="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              sx={{ width: '40%' }}
            />
          </div>
        </div>
      </div>
      <div className={styles.expBioOuter}>
        <h1>Experience and Bio</h1>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
            backgroundColor: '#DDDDDD',
            paddingTop: '30px',
            paddingBottom: '30px'
          }}>
          <TextField
            id="outlined-multiline-static"
            label="Multiline"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            multiline
            rows={4}
            sx={{ width: '40%' }}
          />
          <TextField
            id="outlined-multiline-static"
            label="Multiline"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            multiline
            rows={4}
            sx={{ width: '40%' }}
          />
        </div>
      </div>
      <div className={styles.basicInfoOuter} style={{ marginBottom: '60px' }}>
        <h1>Bank Information</h1>
        <div
          style={{
            width: '100%',
            backgroundColor: '#DDDDDD',
            paddingTop: '10px',
            paddingBottom: '10px'
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '30px',
              marginBottom: '30px'
            }}>
            <TextField
              required
              id="outlined-required"
              label="Bank Name"
              onChange={(e) => setBank_Name(e.target.value)}
              value={bank_name}
              sx={{ width: '40%' }}
            />
            <TextField
              required
              id="outlined-required"
              value={bank_address1}
              onChange={(e) => setBank_Address1(e.target.value)}
              label="Address Location 1"
              sx={{ width: '40%' }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '30px',
              marginBottom: '30px'
            }}>
            <TextField
              required
              id="outlined-required"
              value={bank_account_holder_name}
              onChange={(e) => setBank_Account_Holder_Name(e.target.value)}
              label="Account Holder Name"
              sx={{ width: '40%' }}
            />
            <TextField
              required
              id="outlined-required"
              value={bank_address2}
              onChange={(e) => setBank_Address2(e.target.value)}
              label="Address Location 2"
              sx={{ width: '40%' }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '30px',
              marginBottom: '30px'
            }}>
            <TextField
              required
              id="outlined-required"
              label="Account Number"
              value={bank_account_number}
              onChange={(e) => setBank_Account_Nuber(e.target.value)}
              sx={{ width: '40%' }}
            />
            <TextField
              required
              id="outlined-required"
              label="City"
              value={bank_city}
              onChange={(e) => setBank_City(e.target.value)}
              sx={{ width: '40%' }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '30px',
              marginBottom: '30px'
            }}>
            <TextField
              required
              id="outlined-required"
              label="IFSC Code"
              value={bank_ifsc_code}
              onChange={(e) => setIFSCCode(e.target.value)}
              sx={{ width: '40%' }}
            />
            <TextField
              required
              id="outlined-required"
              label="Country"
              value={bank_country}
              onChange={(e) => setBank_Country(e.target.value)}
              sx={{ width: '40%' }}
            />
          </div>
        </div>
      </div>
      <div className={styles.buttonOuter}>
        <Button variant="contained" sx={{ width: '20vw' }} onClick={() => handleSave()}>
          Save Changes
        </Button>
      </div>
    </div>
  );
};
export default Account;
